import { Form, Input, Button, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import GlobalSkills from "./GlobalSkills";
import { Select } from "antd";

import {
  ChargesData,
  UpdateChargesData,
  UpdateProfile,
} from "../../../Services/apiClient";
function AdminSetting() {
  const { Option } = Select;

  const [formEnable, setFormEnable] = useState(true);
  const [eaRates, setEaRates] = useState(0);
  const [esCrow, setEsCrow] = useState(0);
  const [disburse, setDisburse] = useState(0);
  const [minJobPrice, setMinJobPrice] = useState(0);
  const [maxJobPrice, setMaxJobPrice] = useState(0);
  const [currency, setCurrency] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getChargesData();
  }, []);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 9,
    },
    wrapperCol: {
      span: 6,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 11,
      span: 14,
    },
  };
  const getChargesData = async () => {
    const respData = await ChargesData();
    setCurrency(respData?.data?.data?.currency);
    setDisburse(respData?.data?.data?.disburse_charges);
    setEsCrow(respData?.data?.data?.escrow_charges);
    setEaRates(respData?.data?.data?.ea_charges);

    setMinJobPrice(respData?.data?.data?.min_job_price);
    setMaxJobPrice(respData?.data?.data?.max_job_price);

    console.log(respData);
  };
  const ChangeEmailPassword = async () => {
    const respChangeEmailPassword = await UpdateProfile(email, password);
    respChangeEmailPassword?.status == 200
      ? message.success("Account settings updated successfully")
      : message.success("Couldn't Update At this very moment");

    console.log(respChangeEmailPassword);
    setEmail("");
    setPassword("");
  };
  const updateCharges = async () => {
    setLoading(true);
    const respChanges = await UpdateChargesData(
      eaRates,
      esCrow,
      disburse,
      currency,
      parseInt(minJobPrice),
      parseInt(maxJobPrice)
    );
    console.log(respChanges);
    respChanges?.data?.status == 200
      ? message.success("Charges Updated Successfully", 2, setLoading(false))
      : message.error("couldn't update Charges", 2, setLoading(false));
  };
  const onChange = (value) => {
    console.log(value);
    setCurrency(() => value);
  };
  const Demo = () => {
    const onFinish = (values) => {
      console.log("Success:", values);
    };
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Tabs
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "5px",
        // border: "1px solid #dedede",
        minHeight: "100vh",
      }}
    >
      <TabPane tab="Account Settings" key="1">
        <div className="adminSetting" style={{}}>
          <div
            className="changePassword"
            style={{
              backgroundColor: "#fefefe",
              padding: "10px 0px",
              margin: "0 auto",
            }}
          >
            <div
              className="changePasswordHeader"
              style={{
                textAlign: "center",
                margin: "0 auto",
                marginBottom: "20px",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              <span>Change Password</span>
            </div>

            <Form {...layout}>
              <Form.Item
                label="Username"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                rules={[
                  {
                    required: false,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input.Password disabled={formEnable} />
              </Form.Item>
              <Form.Item
                name="newpassword"
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                rules={[
                  {
                    // required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password disabled={formEnable} />
              </Form.Item>
              {/* <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["newpassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newpassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password disabled={formEnable} />
              </Form.Item> */}
              <Form.Item {...tailLayout}>
                <Button
                  style={{
                    marginTop: "20px",
                  }}
                  disabled={formEnable}
                  type="primary"
                  htmlType="submit"
                  onClick={() => ChangeEmailPassword()}
                >
                  Update
                </Button>
                <span
                  style={{
                    marginLeft: "20px",
                    fontSize: "11px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => setFormEnable((prev) => !prev)}
                >
                  {formEnable ? "Edit" : "Cancel"}
                </span>
              </Form.Item>
            </Form>
          </div>
          <div
            className="Price"
            style={{
              backgroundColor: "white",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            <div
              className="rates"
              style={{
                margin: "0 auto",
              }}
            ></div>
            <div
              className="firstForm"
              style={{
                margin: "0 auto  ",

                display: "flex",
                gap: "20px",
                flexDirection: "column",
                justifyContent: "space-between",

                padding: "10px",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "tomato",
                  alignSelf: "center",
                }}
              >
                Change rates
              </span>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <Input
                  style={{
                    width: "120px",
                    alignSelf: "center",
                    border: "1px solid #ffffff",
                    backgroundColor: "#f7f7f7",
                    borderTop: "none",
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                  prefix="%"
                  size="large"
                  onChange={(e) => setEaRates(parseFloat(e.target.value))}
                  value={eaRates}
                  type="number"
                  suffix="EAC"
                />
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <h1>Everywhere Agent Charges</h1>
                  <span>For Changing the percentage rate of (EA) </span>
                </div>
              </Form>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <Input
                  style={{
                    width: "120px",
                    alignSelf: "center",
                    border: "1px solid #ffffff",
                    backgroundColor: "#f7f7f7",
                    borderTop: "none",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                  prefix="%"
                  type="float"
                  size="small"
                  maxLength="2"
                  onChange={(e) => setEsCrow(e.target.value)}
                  value={esCrow}
                  suffix="ECC"
                />
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <h3>Escrow Charges</h3>
                  <span>For updating the percentage rate of (EsCrow) </span>
                </div>
              </Form>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <Input
                  style={{
                    width: "120px",
                    alignSelf: "center",
                    borderTop: "1px solid #ffffff",

                    backgroundColor: "#f7f7f7",

                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                  prefix="%"
                  type="number"
                  maxLength="2"
                  onChange={(e) => setDisburse(e.target.value)}
                  value={disburse}
                  suffix="DBC"
                />
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <h3>Disburse Charges</h3>
                  <span>For updating the percentage rate of (DB) </span>
                </div>
              </Form>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <Input
                  style={{
                    width: "120px",
                    alignSelf: "center",
                    borderTop: "1px solid #ffffff",

                    backgroundColor: "#f7f7f7",

                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                  prefix="$"
                  type="number"
                  maxLength="2"
                  onChange={(e) => setMinJobPrice(e.target.value)}
                  value={minJobPrice}
                  suffix=""
                />
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <h3>Min Job Price</h3>
                  <span>For updating global minimum job price </span>
                </div>
              </Form>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <Input
                  style={{
                    width: "120px",
                    alignSelf: "center",
                    borderTop: "1px solid #ffffff",

                    backgroundColor: "#f7f7f7",

                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                  prefix="$"
                  type="number"
                  maxLength="2"
                  onChange={(e) => setMaxJobPrice(e.target.value)}
                  value={maxJobPrice}
                  suffix=""
                />
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <h3>Max Job Price</h3>
                  <span>For updating global maximum job price </span>
                </div>
              </Form>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                {/* <Input
                  style={{
                    width: "120px",
                    alignSelf: "center",
                    borderTop: "1px solid #ffffff",
                    backgroundColor: "#f7f7f7",

                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                  prefix="$"
                  type="string"
                  maxLength="4"
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                  suffix="CUR"
                /> */}
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f7f7f7",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h1>Currency</h1>
                  <span
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    For Changing the Currency Type <br /> Enter the Currency
                    Type{" "}
                  </span>

                  <Select
                    showSearch
                    style={{}}
                    placeholder="currency"
                    // optionFilterProp="children"
                    onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    defaultActiveFirstOption={true}
                    value={currency}
                    style={{
                      maxWidth: "150px",
                      alignSelf: "center",
                    }}
                    dropdownStyle={{ minWidth: "150px" }}
                  >
                    {/* <Option value="EUR" label="Euro">EUR</Option> */}
                    <Option value="USD" label="US dollar">
                      USD
                    </Option>

                    {/* <Option value="JPY" label="Japanese yen">JPY</Option>
    <Option value="GBP" label="Pound sterling">GBP</Option>
    <Option disabled>──────────</Option>
    <Option value="AED" label="United Arab Emirates dirham">AED</Option>
    <Option value="AFN" label="Afghan afghani">AFN</Option>
    <Option value="ALL" label="Albanian lek">ALL</Option>
    <Option value="AMD" label="Armenian dram">AMD</Option>
    <Option value="ANG" label="Netherlands Antillean guilder">ANG</Option>
    <Option value="AOA" label="Angolan kwanza">AOA</Option>
    <Option value="ARS" label="Argentine peso">ARS</Option>
    <Option value="AUD" label="Australian dollar">AUD</Option>
    <Option value="AWG" label="Aruban florin">AWG</Option>
    <Option value="AZN" label="Azerbaijani manat">AZN</Option>
    <Option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</Option>
    <Option value="BBD" label="Barbadian dollar">BBD</Option>
    <Option value="BDT" label="Bangladeshi taka">BDT</Option>
    <Option value="BGN" label="Bulgarian lev">BGN</Option>
    <Option value="BHD" label="Bahraini dinar">BHD</Option>
    <Option value="BIF" label="Burundian franc">BIF</Option>
    <Option value="BMD" label="Bermudian dollar">BMD</Option>
    <Option value="BND" label="Brunei dollar">BND</Option>
    <Option value="BOB" label="Bolivian boliviano">BOB</Option>
    <Option value="BRL" label="Brazilian real">BRL</Option>
    <Option value="BSD" label="Bahamian dollar">BSD</Option>
    <Option value="BTN" label="Bhutanese ngultrum">BTN</Option>
    <Option value="BWP" label="Botswana pula">BWP</Option>
    <Option value="BYN" label="Belarusian ruble">BYN</Option>
    <Option value="BZD" label="Belize dollar">BZD</Option>
    <Option value="CAD" label="Canadian dollar">CAD</Option>
    <Option value="CDF" label="Congolese franc">CDF</Option>
    <Option value="CHF" label="Swiss franc">CHF</Option>
    <Option value="CLP" label="Chilean peso">CLP</Option>
    <Option value="CNY" label="Chinese yuan">CNY</Option>
    <Option value="COP" label="Colombian peso">COP</Option>
    <Option value="CRC" label="Costa Rican colón">CRC</Option>
    <Option value="CUC" label="Cuban convertible peso">CUC</Option>
    <Option value="CUP" label="Cuban peso">CUP</Option>
    <Option value="CVE" label="Cape Verdean escudo">CVE</Option>
    <Option value="CZK" label="Czech koruna">CZK</Option>
    <Option value="DJF" label="Djiboutian franc">DJF</Option>
    <Option value="DKK" label="Danish krone">DKK</Option>
    <Option value="DOP" label="Dominican peso">DOP</Option>
    <Option value="DZD" label="Algerian dinar">DZD</Option>
    <Option value="EGP" label="Egyptian pound">EGP</Option>
    <Option value="ERN" label="Eritrean nakfa">ERN</Option>
    <Option value="ETB" label="Ethiopian birr">ETB</Option>
    <Option value="EUR" label="EURO">EUR</Option>
    <Option value="FJD" label="Fijian dollar">FJD</Option>
    <Option value="FKP" label="Falkland Islands pound">FKP</Option>
    <Option value="GBP" label="British pound">GBP</Option>
    <Option value="GEL" label="Georgian lari">GEL</Option>
    <Option value="GGP" label="Guernsey pound">GGP</Option>
    <Option value="GHS" label="Ghanaian cedi">GHS</Option>
    <Option value="GIP" label="Gibraltar pound">GIP</Option>
    <Option value="GMD" label="Gambian dalasi">GMD</Option>
    <Option value="GNF" label="Guinean franc">GNF</Option>
    <Option value="GTQ" label="Guatemalan quetzal">GTQ</Option>
    <Option value="GYD" label="Guyanese dollar">GYD</Option>
    <Option value="HKD" label="Hong Kong dollar">HKD</Option>
    <Option value="HNL" label="Honduran lempira">HNL</Option>
    <Option value="HKD" label="Hong Kong dollar">HKD</Option>
    <Option value="HRK" label="Croatian kuna">HRK</Option>
    <Option value="HTG" label="Haitian gourde">HTG</Option>
    <Option value="HUF" label="Hungarian forint">HUF</Option>
    <Option value="IDR" label="Indonesian rupiah">IDR</Option>
    <Option value="ILS" label="Israeli new shekel">ILS</Option>
    <Option value="IMP" label="Manx pound">IMP</Option>
    <Option value="INR" label="Indian rupee">INR</Option>
    <Option value="IQD" label="Iraqi dinar">IQD</Option>
    <Option value="IRR" label="Iranian rial">IRR</Option>
    <Option value="ISK" label="Icelandic króna">ISK</Option>
    <Option value="JEP" label="Jersey pound">JEP</Option>
    <Option value="JMD" label="Jamaican dollar">JMD</Option>
    <Option value="JOD" label="Jordanian dinar">JOD</Option>
    <Option value="JPY" label="Japanese yen">JPY</Option>
    <Option value="KES" label="Kenyan shilling">KES</Option>
    <Option value="KGS" label="Kyrgyzstani som">KGS</Option>
    <Option value="KHR" label="Cambodian riel">KHR</Option>
    <Option value="KID" label="Kiribati dollar">KID</Option>
    <Option value="KMF" label="Comorian franc">KMF</Option>
    <Option value="KPW" label="North Korean won">KPW</Option>
    <Option value="KRW" label="South Korean won">KRW</Option>
    <Option value="KWD" label="Kuwaiti dinar">KWD</Option>
    <Option value="KYD" label="Cayman Islands dollar">KYD</Option>
    <Option value="KZT" label="Kazakhstani tenge">KZT</Option>
    <Option value="LAK" label="Lao kip">LAK</Option>
    <Option value="LBP" label="Lebanese pound">LBP</Option>
    <Option value="LKR" label="Sri Lankan rupee">LKR</Option>
    <Option value="LRD" label="Liberian dollar">LRD</Option>
    <Option value="LSL" label="Lesotho loti">LSL</Option>
    <Option value="LYD" label="Libyan dinar">LYD</Option>
    <Option value="MAD" label="Moroccan dirham">MAD</Option>
    <Option value="MDL" label="Moldovan leu">MDL</Option>
    <Option value="MGA" label="Malagasy ariary">MGA</Option>
    <Option value="MKD" label="Macedonian denar">MKD</Option>
    <Option value="MMK" label="Burmese kyat">MMK</Option>
    <Option value="MNT" label="Mongolian tögrög">MNT</Option>
    <Option value="MOP" label="Macanese pataca">MOP</Option>
    <Option value="MRU" label="Mauritanian ouguiya">MRU</Option>
    <Option value="MUR" label="Mauritian rupee">MUR</Option>
    <Option value="MVR" label="Maldivian rufiyaa">MVR</Option>
    <Option value="MWK" label="Malawian kwacha">MWK</Option>
    <Option value="MXN" label="Mexican peso">MXN</Option>
    <Option value="MYR" label="Malaysian ringgit">MYR</Option>
    <Option value="MZN" label="Mozambican metical">MZN</Option>
    <Option value="NAD" label="Namibian dollar">NAD</Option>
    <Option value="NGN" label="Nigerian naira">NGN</Option>
    <Option value="NIO" label="Nicaraguan córdoba">NIO</Option>
    <Option value="NOK" label="Norwegian krone">NOK</Option>
    <Option value="NPR" label="Nepalese rupee">NPR</Option>
    <Option value="NZD" label="New Zealand dollar">NZD</Option>
    <Option value="OMR" label="Omani rial">OMR</Option>
    <Option value="PAB" label="Panamanian balboa">PAB</Option>
    <Option value="PEN" label="Peruvian sol">PEN</Option>
    <Option value="PGK" label="Papua New Guinean kina">PGK</Option>
    <Option value="PHP" label="Philippine peso">PHP</Option>
    <Option value="PKR" label="Pakistani rupee">PKR</Option>
    <Option value="PLN" label="Polish złoty">PLN</Option>
    <Option value="PRB" label="Transnistrian ruble">PRB</Option>
    <Option value="PYG" label="Paraguayan guaraní">PYG</Option>
    <Option value="QAR" label="Qatari riyal">QAR</Option>
    <Option value="RON" label="Romanian leu">RON</Option>
    <Option value="RON" label="Romanian leu">RON</Option>
    <Option value="RSD" label="Serbian dinar">RSD</Option>
    <Option value="RUB" label="Russian ruble">RUB</Option>
    <Option value="RWF" label="Rwandan franc">RWF</Option>
    <Option value="SAR" label="Saudi riyal">SAR</Option>
    <Option value="SEK" label="Swedish krona">SEK</Option>
    <Option value="SGD" label="Singapore dollar">SGD</Option>
    <Option value="SHP" label="Saint Helena pound">SHP</Option>
    <Option value="SLL" label="Sierra Leonean leone">SLL</Option>
    <Option value="SLS" label="Somaliland shilling">SLS</Option>
    <Option value="SOS" label="Somali shilling">SOS</Option>
    <Option value="SRD" label="Surinamese dollar">SRD</Option>
    <Option value="SSP" label="South Sudanese pound">SSP</Option>
    <Option value="STN" label="São Tomé and Príncipe dobra">STN</Option>
    <Option value="SYP" label="Syrian pound">SYP</Option>
    <Option value="SZL" label="Swazi lilangeni">SZL</Option>
    <Option value="THB" label="Thai baht">THB</Option>
    <Option value="TJS" label="Tajikistani somoni">TJS</Option>
    <Option value="TMT" label="Turkmenistan manat">TMT</Option>
    <Option value="TND" label="Tunisian dinar">TND</Option>
    <Option value="TOP" label="Tongan paʻanga">TOP</Option>
    <Option value="TRY" label="Turkish lira">TRY</Option>
    <Option value="TTD" label="Trinidad and Tobago dollar">TTD</Option>
    <Option value="TVD" label="Tuvaluan dollar">TVD</Option>
    <Option value="TWD" label="New Taiwan dollar">TWD</Option>
    <Option value="TZS" label="Tanzanian shilling">TZS</Option>
    <Option value="UAH" label="Ukrainian hryvnia">UAH</Option>
    <Option value="UGX" label="Ugandan shilling">UGX</Option>
    <Option value="USD" label="United States dollar">USD</Option>
    <Option value="UYU" label="Uruguayan peso">UYU</Option>
    <Option value="UZS" label="Uzbekistani soʻm">UZS</Option>
    <Option value="VES" label="Venezuelan bolívar soberano">VES</Option>
    <Option value="VND" label="Vietnamese đồng">VND</Option>
    <Option value="VUV" label="Vanuatu vatu">VUV</Option>
    <Option value="WST" label="Samoan tālā">WST</Option>
    <Option value="XAF" label="Central African CFA franc">XAF</Option>
    <Option value="XCD" label="Eastern Caribbean dollar">XCD</Option>
    <Option value="XOF" label="West African CFA franc">XOF</Option>
    <Option value="XPF" label="CFP franc">XPF</Option>
    <Option value="ZAR" label="South African rand">ZAR</Option>
    <Option value="ZMW" label="Zambian kwacha">ZMW</Option>
    <Option value="ZWB" label="Zimbabwean bonds">ZWB</Option> */}
                  </Select>
                </div>
              </Form>
              <Button
                loading={loading}
                type="primary"
                onClick={() => updateCharges()}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </TabPane>
      <TabPane tab="Update Skills" key="2">
        <GlobalSkills />
      </TabPane>
    </Tabs>
  );
}

export default AdminSetting;
