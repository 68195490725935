import React from "react";
import { Modal, Button, Radio } from "antd";
import { useHistory } from "react-router";

const CancelJob = (props) => {
  //radio Button things
  const [value, setValue] = React.useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  //closing radio button
  console.log(props);
  return (
    <>
      <Button disabled={props.showResolveButton} type="secondary" onClick={props.showCancelJobModal}>
        Cancel Job
      </Button>
      <Modal
        title="Cancel With Refund"
        visible={props.cancelJobVisible}
        onOk={() => props.handleOkk(value == 1 ? "PARTIAL" : "FULL")}
        confirmLoading={props.confirmLoading}
        onCancel={props.handleCancel}
      >
        <p>Choose Refund Option</p>

        <div className="radioContainer">
          <Radio.Group onChange={onChange} value={value}>
            {/* <Radio value={1}>No Refund </Radio> */}
            <Radio value={1}>Partial Refund </Radio>
            <Radio value={2}>Full Refund</Radio>
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
};

export default CancelJob;
