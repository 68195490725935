import React, { useEffect, useState } from "react";

import { Line } from "@ant-design/charts";

import "./chart.css";

const RevenueChart = ({ chartData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("revenue chart data", chartData);
    setData(chartData);
  }, [chartData]);

  const config = {
    data,
    padding: "auto",
    xField: "date",
    yField: "amount",
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
  };
  return (
    <div className="chart-container">
      {data?.length === 0 ? <h3>No Data Available</h3> : <Line {...config} />}
    </div>
  );
};

export default RevenueChart;
