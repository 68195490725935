import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import { AllJobDisputes } from "../../../Services/apiClient";
import { Link, useParams } from "react-router-dom";
import Tags from "../../../Components/MeterComponents/Tags";

function JobDisputes({ id }) {
  const [jobDisputeData, setJobDisputeData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(1);

  useEffect(() => {
    getJobDisputes();
  }, [offset]);

  const getJobDisputes = async () => {
    setLoader(true);
    try {
      const DisputesData = await AllJobDisputes(id, offset);
      setJobDisputeData(DisputesData.data.data.disputes);
      setTotalRows(DisputesData?.data?.data.count);
      setLoader(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      render: (indexData, record) => {
        return <span>{new Date(indexData * 1000).toLocaleDateString()}</span>;
      },
      responsive: ["sm"],
    },
    {
      title: "Status",
      dataIndex: "status",
      render(h) {
        return <Tags status={h} />;
      },
    },
    {
      title: "Raised-by",
      dataIndex: ["raised_by", "firstname"],
      render: (indexData, record) => {
        return (
          <Link
            to={
              record.user_type == "CLIENT"
                ? `/clientProfile/${record.raised_by.id}`
                : `/agentProfile/${record.raised_by.id}`
            }
          >
            {`${record.raised_by.first_name} ${record.raised_by.last_name}`}
          </Link>
        );
      },
      responsive: ["md"],
    },
    { title: "User Type", dataIndex: "user_type", responsive: ["lg"] },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id, record) => {
        return (
          <Link to={`/jobDescription/${record.job_id}/dispute/${id}`}>
            View
          </Link>
        );
      },
    },
  ];

  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div>
      {
        <>
          <Table
            rowKey={(record) => record._key}
            loading={loader}
            columns={columns}
            dataSource={jobDisputeData ? jobDisputeData : null}
            pagination={false}
          />
          <div className="pagination">
            <Pagination
              total={totalRows}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
              defaultCurrent={1}
              current={offset}
            />
          </div>
        </>
      }
    </div>
  );
}

export default JobDisputes;
