import React, { useEffect, useState } from "react";
import {
  SnippetsOutlined,
  // StarOutlined,
  ScheduleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Skeleton, Tabs } from "antd";
import JobsPosted from "../../JobFilters/JobsPosted";

import AvatarProfile from "../../../Components/Profile/AvatarProfile";
import ProfileInfo from "../../../Components/UserInfo/ProfileInfo/ProfileInfo";
import { SingleClient } from "../../../Services/apiClient";
import { useParams } from "react-router";
import EditClientProfile from "./EditClientProfile";
import "./ClientProfile.css";
// import ClientSubscriptions from "./ClientSubscriptions";

const ClientProfile = (props) => {
  const { id } = useParams();
  const [clientProfile, setClientProfile] = useState({});
  const [cSkeleton, setCSkeleton] = useState(true);
  const [clientJobsPosted, setClientJobsPosted] = useState([]);
  const [showEditClientForm, setShowEditClientForm] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);
  const { TabPane } = Tabs;

  useEffect(() => {
    ClientProfileGetData();
  }, [refreshForm]);

  const CloseClientForm = () => {
    setShowEditClientForm(false);
  };
  const refreshClientPage = () => {
    setRefreshForm((prev) => !prev);
  };

  const ClientProfileGetData = async () => {
    try {
      const resp = await SingleClient(id);
      setClientProfile(resp?.ClientData?.data?.data);
      setClientJobsPosted(resp?.JobsPosted?.data?.data?.jobs);
      setCSkeleton(false);
      console.log("client response", resp);
    } catch (error) {
      console.log("clientProfileGetData", error);
    } finally {
    }
  };

  return (
    <>
      <div
        className="clientProfile"
        style={{
          margin: "0 auto",
        }}
      >
        <EditClientProfile
          id={id}
          showEditClientForm={showEditClientForm}
          CloseClientForm={CloseClientForm}
          refreshClientPage={refreshClientPage}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Client Profile</h2>
          <Button
            icon={
              <EditOutlined
                style={{
                  fontSize: "13px",
                  color: "GrayText",
                }}
              />
            }
            style={{
              padding: 0,
              fontSize: "13px",
            }}
            type="link"
            onClick={() => setShowEditClientForm(true)}
          >
            Edit Profile
          </Button>
        </div>
        <Skeleton loading={cSkeleton} active>
          <AvatarProfile
            name={`${clientProfile?.first_name} ${clientProfile?.last_name}`}
            location={`${clientProfile?.city ?? "-"} ${
              clientProfile?.state ?? "-"
            } ${clientProfile?.country ?? "-"}`}
            avatarSrc={clientProfile?.avatar}
            shape="circle"
            isClient={true}
            // planType={clientProfile?.plan_type}
          />
        </Skeleton>

        <Tabs defaultActiveKey="0">
          <TabPane
            tab={
              <span>
                <SnippetsOutlined />
                About
              </span>
            }
            key="0"
          >
            <Skeleton loading={cSkeleton} active>
              <ProfileInfo
                name={`${clientProfile?.first_name} ${clientProfile?.last_name}`}
                email={clientProfile?.email ?? "--"}
                phone={`${clientProfile?.country_code}-${
                  clientProfile?.phone ?? "--"
                }`}
                total_ratings={clientProfile?.total_ratings}
                location={`${clientProfile?.city ?? "-"} ${
                  clientProfile?.state ?? "-"
                } ${clientProfile?.country ?? "-"}`}
                registered={clientProfile?.created_at ?? "--"}
                status={clientProfile?.account_status?.status ?? "--"}
                id={clientProfile?.id ?? "--"}
                level={clientProfile?.level ?? "--"}
                phone_verified={clientProfile?.phone_verified ?? "--"}
                address={`${clientProfile?.city ?? "-"} ${
                  clientProfile?.state ?? "-"
                } ${clientProfile?.country ?? "-"}`}
                spent={clientProfile?.total_spent ?? 0}
                isClient={true}
              />
            </Skeleton>

            {clientProfile?.description ? (
              <div style={{ backgroundColor: "white", padding: "10px" }}>
                <h2>Bio</h2>
                <p>{clientProfile?.description || "--"}</p>
              </div>
            ) : null}
          </TabPane>
          <TabPane
            tab={
              <span>
                <ScheduleOutlined />
                Jobs Posted
              </span>
            }
            key="2"
          >
            {clientJobsPosted.length !== 0 ? (
              clientJobsPosted?.map((job) => <JobsPosted data={job} />)
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3>No Jobs Available</h3>
              </div>
            )}
          </TabPane>
          {/* <TabPane
            tab={
              <span>
                <StarOutlined />
                Subscriptions
              </span>
            }
            key="3"
          >
            <ClientSubscriptions clientId={id} />
          </TabPane> */}
        </Tabs>
      </div>
    </>
  );
};

export default ClientProfile;
