import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Input, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { UpdateSkills, GetSkills } from "../../../Services/apiClient";

function GlobalSkills() {
  useEffect(() => {
    getSkillData();
  }, []);

  const getSkillData = async () => {
    const respGetSkillData = await GetSkills();
    console.log(respGetSkillData);
    setSkills(respGetSkillData?.data?.data);
  };

  const [skills, setSkills] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleClose = (removedTag) => {
    const updatedSkills = skills.filter((tag) => tag !== removedTag);
    console.log("updated", updatedSkills);
    console.log(skills);
    CommonUpdate(updatedSkills);
  };

  const CommonUpdate = async (arr) => {
    console.log("arr", arr);
    const respUpdateSkillsList = await UpdateSkills(arr);
    console.log("respupdateskillslist", respUpdateSkillsList);
    setSkills(respUpdateSkillsList?.data?.data);
  };
  const UpdateSkillsList = () => {
    console.log("input:", inputValue);
    // let updatedSkills = skills?.filter((tag) => true);
    let updatedSkills = skills;
    updatedSkills?.push(inputValue);
    console.log("UpdatedSkills", updatedSkills);
    CommonUpdate([...new Set(updatedSkills)]);
    console.log("Skills:", skills);
    setInputValue("");
  };

  return (
    <div>
      <div
        className="skillContainer"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className="addSkill"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <Input
            size="small"
            style={{
              width: "fit-content",
            }}
            value={inputValue}
            onPressEnter={() => {
              if (inputValue.trim().length != 0) {
                UpdateSkillsList();
              }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Add Skills"
          ></Input>
          <Button
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              if (inputValue.trim().length != 0) {
                UpdateSkillsList();
              }
            }}
            className="site-tag-plus"
          >
            <PlusSquareOutlined /> Add Skill
          </Button>
        </div>
        <div
          className="skills"
          style={{
            border: "1px solid #dedede",
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
            width: "-webkit-fill-available",
            minHeight: "100px",
            backgroundColor: "#FAFAFB",
            borderRadius: "5px",
            // boxShadow: "rgb(241 241 241) 1px 1px 6px 1px",
          }}
        >
          {skills?.map((tag, index) => {
            return (
              <Tag
                key={index}
                color="success"
                style={{
                  marginBottom: "10px",
                  height: "fit-content",
                }}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(tag);
                }}
              >
                {tag}
              </Tag>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default GlobalSkills;
