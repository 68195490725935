import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import millify from "millify";
import { Input, Space } from "antd";
import { Link } from "react-router-dom";
// import JobsListDate from "../../../Components/DateForLists/JobsListDate";
import SelectFilter from "../../../Components/Button/SelectFilter";
import Tags from "../../../Components/MeterComponents/Tags";
import { AllAgents } from "../../../Services/apiClient";

function AgentList() {
  const [agents, setAgents] = useState([]);
  const [agentStatusValue, setAgentStatusValue] = useState("ALL");
  const [agentSearchValue, setAgentSearchValue] = useState("");
  const [rateType, setRateType] = useState("ALL");
  const [rateValue, setRateValue] = useState("ALL");
  const [agentListLoader, setAgentListLoader] = useState(true);
  const [offset, setOffset] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(1);

  const data = [
    "ALL",
    "SUSPENDED",
    "UNVERIFIED",
    "VERIFIED",
    "UNDER_PROCESS",
    "DEACTIVATED",
    "REJECTED",
  ];

  const rateData = ["ALL", "Hourly Rate", "Fixed Rate", "Open to Offers"];

  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  let mounted = true;

  useEffect(() => {
    getAgentList();
    return () => {
      mounted = false;
    };
  }, [agentSearchValue, agentStatusValue, offset, rateType]);

  const getAgentList = async () => {
    console.log("agent status and rate value", agentStatusValue, rateType);
    setAgentListLoader(true);
    try {
      const agentListData = await AllAgents(
        agentStatusValue === "ALL" ? "" : agentStatusValue.toLocaleUpperCase(),
        rateType === "ALL" ? "" : rateType,
        agentSearchValue,
        offset
      );
      mounted && setAgents(agentListData?.data?.data?.clients);
      setTotalRows(agentListData?.data?.data?.count);
    } catch (error) {
      console.log("getAgentlist", error);
    } finally {
      mounted && setAgentListLoader(false);
    }
  };
  const getData = (value) => {
    setOffset(1);
    console.log("in agent list getdata");
    setAgentStatusValue(value);
  };

  const getRateData = (value) => {
    let rateType = {
      hourly: { label: "Hourly Rate", value: "HOURLY_RATE" },
      fixed: { label: "Fixed Rate", value: "FIXED_RATE" },
      open: { label: "Open to Offers", value: "OPEN_TO_OFFERS" },
    };
    setOffset(1);
    setRateValue(value);
    setRateType(
      value === rateType.hourly.label
        ? rateType.hourly.value
        : value === rateType.fixed.label
        ? rateType.fixed.value
        : value === rateType.open.label
        ? rateType.open.value
        : "ALL"
    );
  };

  const columns = [
    {
      title: "Agent ID",
      dataIndex: "id",
      render: (id, agent) => {
        switch (agent.account_status.status.toLowerCase()) {
          case "unverified":
            return (
              <Link to={`/agentVerification/${agent.id}`}>
                <span>{id.slice(0, 6)}</span>{" "}
              </Link>
            );

          case "rejected":
            return (
              <Link to={`/agentVerification/${agent.id}`}>
                <span>{id.slice(0, 6)}</span>{" "}
              </Link>
            );
          case "under_process":
            return (
              <Link to={`/agentVerification/${agent.id}`}>
                <span>{id.slice(0, 6)}</span>{" "}
              </Link>
            );

          default:
            return (
              <Link to={`/agentProfile/${agent.id}`}>
                <span>{id.slice(0, 6)}</span>{" "}
              </Link>
            );
        }
      },
      responsive: ["lg"],
    },
    {
      title: "Agent Name",
      dataIndex: "first_name",
      render: (agentname, agent) => {
        switch (agent.account_status.status.toLowerCase()) {
          case "unverified":
            return (
              <Link to={`/agentVerification/${agent.id}`}>
                <span>
                  {agentname}
                  {` ${agent.last_name}`}
                </span>{" "}
              </Link>
            );

          case "rejected":
            return (
              <Link to={`/agentVerification/${agent.id}`}>
                <span>
                  {agentname}
                  {` ${agent.last_name}`}
                </span>{" "}
              </Link>
            );
          case "under_process":
            return (
              <Link to={`/agentVerification/${agent.id}`}>
                <span>
                  {agentname}
                  {` ${agent.last_name}`}
                </span>{" "}
              </Link>
            );

          default:
            return (
              <Link to={`/agentProfile/${agent.id}`}>
                <span>
                  {agentname}
                  {` ${agent.last_name}`}
                </span>{" "}
              </Link>
            );
        }
      },
    },
    {
      title: "Agent Status",
      dataIndex: ["account_status", "status"],
      render: (status) => {
        return <Tags status={status} />;
      },
    },
    {
      title: "Jobs Completed",
      dataIndex: "jobs_completed",
      responsive: ["md"],
    },
    {
      title: "Earned",
      dataIndex: "total_earned",
      render: (earned) => {
        return <span>$ {millify(earned)}</span>;
      },
    },
    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      render: (earned) => {
        return <span>$ {millify(earned) ?? 0}</span>;
      },
    },
    {
      title: "Fixed Rate",
      dataIndex: "fixed_rate",
      render: (earned) => {
        return <span>$ {millify(earned) ?? 0}</span>;
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div className="agentList">
      <div
        className="agentContainer"
        style={{
          margin: "0 auto",
          position: "relative",
          border: "1px solid #efefef",
        }}
      >
        <div className="innerJoblists" style={{}}>
          <div
            className="jobSearch"
            style={{
              display: "flex",
              // justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              className="selectFilter"
              style={
                {
                  // flex: 0.3,
                }
              }
            >
              <SelectFilter
                getData={getData}
                placeholder="Status"
                jobFilter={agentStatusValue}
                data={data}
              />
            </div>
            <div className="selectFilter">
              <SelectFilter
                getData={getRateData}
                placeholder="Rate Type"
                jobFilter={rateValue}
                data={rateData}
              />
            </div>
            <div
              className="jobsearch__input"
              style={{
                flex: 2,
                width: "100%",
              }}
            >
              <Space direction="vertical">
                <Search
                  placeholder="Search Agents Here"
                  allowClear
                  enterButton="Search"
                  size="medium"
                  onSearch={onSearch}
                  onChange={(e) => {
                    setAgentSearchValue(e.target.value);
                  }}
                />
              </Space>
            </div>
          </div>
          <Table
            columns={columns}
            loading={agentListLoader}
            dataSource={agents}
            onChange={onChange}
            pagination={false}
          />
          <div className="pagination">
            <Pagination
              total={totalRows}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
              defaultCurrent={1}
              current={offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentList;
