import { Button, Modal, message } from "antd";
import React from "react";
import { cancelSubscription } from "../../../Services/apiClient";

const CancelModal = ({ handleOk, handleCancel, open, subscriptionId }) => {
  const handleCancellation = async () => {
    const values = {
      subscription_id: subscriptionId,
    };
    try {
      const res = await cancelSubscription({ values: values });
      const { status } = res;
      if (status === 200) {
        handleOk();
        message.success("Plan updated successfully");
      } else {
        handleOk();
        message.error("Something Went wrong");
      }
    } catch (err) {
      message.error("Something Went wrong");
      console.log(err);
    } finally {
    }
  };
  return (
    <Modal
      visible={open}
      title="Cancel Subscription"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleCancellation} danger>
          Cancel
        </Button>,
      ]}
    >
      <div>
        <h3>Are you sure, You want to cancel subscription?</h3>
      </div>
    </Modal>
  );
};

export default CancelModal;
