import { Button, Modal, Skeleton, message } from "antd";
import React, { useEffect, useState } from "react";
import AvatarProfile from "../../../Components/Profile/AvatarProfile";
import ProfileInfo from "../../../Components/UserInfo/ProfileInfo/ProfileInfo";
import { isPdf, isImage } from "./Pdfimg";
import ImageDemo from "./ImgPreview";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  SingleAgent,
  AgentVerificationLink,
} from "../../../Services/apiClient";
import "./AgentVerification.css";

import { useHistory, useParams } from "react-router";
import { createRef } from "react";
function AgentVerification() {
  const { id } = useParams();
  const history = useHistory();
  let myRef = createRef();
  const { confirm } = Modal;
  const [isView, setIsView] = useState(false);
  const [rejectReason, setRejectReason] = useState();
  const [vSkeleton, setVSkeleton] = useState(true);
  const [unverifiedAgent, setUnverifiedAgent] = useState({});
  console.log(rejectReason);
  useEffect(() => {
    getUnverifiedAgentData();
  }, []);
  useEffect(() => {}, []);
  const getUnverifiedAgentData = async () => {
    try {
      const unverifiedData = await SingleAgent(id);
      console.log(unverifiedData);
      setUnverifiedAgent(unverifiedData?.AgentData?.data?.data);
      unverifiedData?.AgentData?.status == 200 && setVSkeleton(false);
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleChange = function (e) {
    console.log("www", e);
    setRejectReason((p) => {
      console.log("pp", p);
      return e;
    });
    console.log("new", rejectReason);
  };
  const DownloadButton = (props) => {
    const downloadFile = () => {
      window.location.href =
        "https://yoursite.com/src/assets/files/exampleDoc.pdf";
    };
    return <button onClick={downloadFile} />;
  };
  const togglePreview = () => {
    setIsView((previousView) => !previousView);
  };
  //for reject
  const proxyVerification = async () => {
    console.log("reason to reject", myRef.current.value);
    const resp = await AgentVerificationLink(
      id,
      "REJECTED",
      myRef.current.value
    );
    return resp;
  };
  const showPromiseConfirm = () => {
    confirm({
      title: "Are you sure to reject this form?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <textarea
          ref={myRef}
          style={{
            width: "100%",
            outline: "none",
            padding: "5px",
            borderRadius: "5px",
            border: "1px solid #efefef",
          }}
          // onChange={(e) => {
          //   handleChange(e.target.value);
          // }}
          placeholder="right your reason here"
        />
      ),
      okType: "danger",
      onOk() {
        return new Promise((resolve, reject) => {
          (async function (params) {
            console.log(rejectReason);
            const resp = await proxyVerification();
            switch (resp?.data?.status) {
              case 200:
                console.log(resp);
                resolve();
                message.warning(
                  `Agent With ID :- ${id.slice(0, 6)} is Rejected`
                );
                setTimeout(() => {
                  history.push(`/agents`);
                }, 2000);
                break;

              default:
                message.error(
                  `Cannot Reject the agent error code :- ${resp?.data?.status}`
                );
                reject();
            }
          })();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  function Approve() {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "You are verifying the Agent Documents",
      onOk() {
        return new Promise((resolve, reject) => {
          (async function (params) {
            const resp = await AgentVerificationLink(id, "VERIFIED");
            switch (resp.data.status) {
              case 200:
                resolve();
                (function (params) {
                  message
                    .loading("Action in progress..", 2.5)
                    .then(() =>
                      message.success(
                        `Agent With ID :- ${id.slice(
                          0,
                          6
                        )} is Successfully Verified`,
                        1
                      )
                    )
                    .then(() => message.info("succefully Approved"))
                    .then(() => history.push(`/agentProfile/${id}`));
                })();
                break;

              default:
                reject();
                message.error(
                  `Cannot approve the Agent Code error :- ${resp.data.status}`,
                  2000
                );
            }
          })();
        }).catch(() => console.log("Oops errors!"));
      },
      okText: "Sure",
      cancelText: "Cancel",
    });
  }
  console.log(unverifiedAgent);
  return (
    <div>
      <div
        className="agentVerification"
        style={{
          margin: "0 auto",
          height: "100%",
          minHeight: "200vh",
        }}
      >
        <h3>Agent Verification</h3>
        <div className="agentVerificationHeader">
          <Skeleton loading={vSkeleton} active>
            <AvatarProfile
              shape="square"
              name={`${unverifiedAgent?.first_name} ${unverifiedAgent?.last_name}`}
              avatarSrc={unverifiedAgent?.avatar}
              location={`${unverifiedAgent?.city ?? "-"} ${
                unverifiedAgent?.state ?? "-"
              } ${unverifiedAgent?.country ?? "-"}`}
              hourlyRate={unverifiedAgent?.hourly_rate ?? 0}
              fixedRate={unverifiedAgent?.fixed_rate ?? 0}
            />
          </Skeleton>
        </div>
        <div
          className="agentVerificationInfo"
          style={{
            margin: "10px 0px",
          }}
        >
          <Skeleton loading={vSkeleton} active>
            <ProfileInfo
              name={`${unverifiedAgent?.first_name} ${unverifiedAgent?.last_name} `}
              email={unverifiedAgent?.email}
              phone={`${unverifiedAgent?.country_code}-${unverifiedAgent?.phone}`}
              total_ratings={unverifiedAgent?.total_ratings}
              location={`${unverifiedAgent?.city ?? "-"} ${
                unverifiedAgent?.state ?? "-"
              } ${unverifiedAgent?.country ?? "-"}`}
              id={unverifiedAgent?.id}
              registered={unverifiedAgent?.created_at}
              status={unverifiedAgent?.account_status?.status}
              spent={unverifiedAgent?.total_earned}
              hourlyRate={unverifiedAgent?.hourly_rate ?? 0}
              fixedRate={unverifiedAgent?.fixed_rate ?? 0}
              page={false}
              ssn={unverifiedAgent?.ssn}
              tin={unverifiedAgent?.tin}
              rejectReasons={unverifiedAgent?.rejectReason}
            />
          </Skeleton>
        </div>
        {unverifiedAgent?.description ? (
          <>
            <h2>Bio</h2>
            <p>{unverifiedAgent?.description}</p>
          </>
        ) : null}
        {/* <h2>Rejection Reasons</h2> */}
        {unverifiedAgent?.account_status?.status === "REJECTED" ? (
          // <div>
          //   {" "}
          //   <h3>Not Uploaded</h3>{" "}
          //   {console.log("reject reasons:", unverifiedAgent.rejection_reasons)}
          // </div>
          <div class="rejectionReasonsContainer">
            <h1
              style={{
                font: "bold",
                fontSize: "1.5rem",
              }}
            >
              Rejection Reasons
            </h1>
            <ol>
              {unverifiedAgent.rejection_reasons.map((reason, index) => (
                <li key={index}>
                  {reason
                    .replace(/_/g, " ")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <div className="container">
            {/* <div
              className="imgContainer"
              style={{
                marginTop: "20px",
                width: "100%",
                border: "1px dashed graytext",
                padding: "20px",
              }}
            >
              {unverifiedAgent?.documents?.length !== 0 ? (
                <div>
                  {unverifiedAgent?.documents?.map((doc) => {
                    return isImage(doc?.file) && <ImageDemo data={doc?.file} />;
                  })}
                </div>
              ) : (
                <div>
                  <h1>NOT AVAILABLE</h1>
                </div>
              )}
            </div> */}
            <div
              className="Skeleton"
              style={{
                marginTop: "20px",
              }}
            >
              {/* {unverifiedAgent?.documents?.length === 0 ? (
                <div>
                  <h2> Not Available</h2>
                </div>
              ) : (
                <div>
                  {isView ? (
                    [
                      unverifiedAgent?.documents?.map((item) => {
                        return isPdf(item.file) ? (
                          <div className="pdfContainer">
                            <iframe
                              height="500px"
                              width="100%"
                              title={item.file}
                              src={`https://docs.google.com/gview?url=${process.env.REACT_APP_MINIO_URL}${item.file}&embedded=true`}
                              frameborder="0"
                            ></iframe>
                            {/* <object
                        width="100%"
                        height="400"
                        data={`http://157.245.99.216:9000/eabucket/${item.file}`}
                        type="application/pdf"
                      ></object>
                          </div>
                        ) : (
                          <h3
                            style={{
                              color: "#ff4d4f",
                            }}
                          >
                            Pdf not found...
                          </h3>
                        );
                      }),
                    ]
                  ) : (
                    <h2>Click below to view the pdf if uploaded...</h2>
                  )}
                </div>
              )} */}
            </div>
          </div>
        )}
        <div
          className="DocumentButton"
          style={{
            display: "flex",
            padding: "20px 0px",
            justifyContent: "space-between",
          }}
        >
          <div className="view">
            {unverifiedAgent?.documents?.findIndex((item) =>
              isPdf(item?.file)
            ) !== -1 && (
              <Button onClick={togglePreview}>
                {isView ? "Close" : "View"}
              </Button>
            )}
          </div>
          <div
            className="action"
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              type="danger"
              disabled={unverifiedAgent?.account_status?.status === "REJECTED"}
              onClick={showPromiseConfirm}
            >
              Reject
            </Button>
            <Button type="primary" onClick={Approve}>
              Approve
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentVerification;
