import React, { useState, useEffect } from "react";
import { Skeleton, Tabs, Tag } from "antd";
import millify from "millify";
import { JobDescriptionData } from "../../../Services/apiClient";
import { AimOutlined } from "@ant-design/icons";
import JobSteps from "../../../Components/Steps/JobSteps";
import Cards from "./Cards";
import JobRequest from "../../JobFilters/JobsRequestTable/JobRequest";
import JobProposals from "../../JobFilters/JobsProposals/JobProposals";
import JobDisputes from "../../JobFilters/JobsDisputes/JobDisputes";
import { Link, useParams } from "react-router-dom";
import Tags from "../../../Components/MeterComponents/Tags";
import ReverseGeoCoder from "./reverseGeoCoder";
import { Card } from 'antd';

function JobDescription() {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const { TabPane } = Tabs;
  const [showRefund, setShowRefund] = useState(false);
  const [refunds, setRefunds] = useState([]);
  useEffect(() => {
    getJobData();
  }, []);
  const getJobData = async () => {
    try {
      const jobData = await JobDescriptionData(id);
      setJobDetails((prev) => jobData?.data?.data);

      setRefunds(jobData?.data?.data?.refunds?.data);

      setShowRefund(jobData?.data?.data?.refunded)
      console.log(jobData);
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  console.log(jobDetails);
  return (
    <div
      className="jobsDescription"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "30px",
        marginBottom: "20px",
        width: "100%",
        margin: "0 auto",
      }}
    >
      {loader ? (
        <Skeleton active paragraph={{ rows: 10 }}></Skeleton>
      ) : (
        <>
          <div
            className="jobsHeader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            {console.log("in html")}
            <div
              className="jobsHeader__left"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2>{jobDetails?.title}</h2>
            </div>
            <div
              className="jobsHeader__right"
              style={{
                alignSelf: "center",
              }}
            >
              <Tags status={jobDetails?.job_status}></Tags>
            </div>
          </div>
          <div
            className="jobsFooter"
            style={{
              display: "flex",
              justifyContent: "space-between ",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div
              className="jobsDate"
              style={{
                padding: "10px",
              }}
            >
              <div
                className="jobsDateHeader"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "GrayText",
                  borderBottom: "1px dashed #efefef",
                }}
              >
                <span>Date</span>
              </div>
              <div
                className="jobsDateText"
                style={{
                  padding: "10px 0px",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "GrayText",
                  }}
                >
                  <span>
                    Start Date :-{" "}
                    {new Date(
                      jobDetails?.start_date * 1000
                    ).toLocaleString()}{" "}
                  </span>
                  <br />
                  <span>End Date :- </span>
                  {jobDetails?.end_date != 0 ? (
                    <span>
                      {new Date(
                        jobDetails?.end_date * 1000
                      ).toLocaleString()}{" "}
                    </span>
                  ) : (
                    <span> Not available</span>
                  )}
                </p>
              </div>
            </div>
            <Link style={{}} to={`/clientProfile/${jobDetails?.client_id}`}>
              <Cards
                title="Posted By"
                name={`${jobDetails?.client?.first_name} 
                        ${jobDetails?.client?.last_name}`}
                ratings={jobDetails?.client?.total_ratings}
                avatar={jobDetails?.client?.avatar}
              />
            </Link>

            <div
              className="jobsLocation"
              style={{
                padding: "10px",
                minWidth: "200px",
              }}
            >
              <div
                className="jobsLocationTitle"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "GrayText",
                  borderBottom: "1px dashed #efefef",
                }}
              >
                <span>Location</span>
              </div>
              <div
                className="jobsLocationDetails"
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <AimOutlined
                  style={{
                    fontSize: "18px !important",
                    color: "GrayText",
                  }}
                />
                <p
                  style={{
                    all: "unset",
                    fontSize: "12px",
                    width: "100%",
                    // padding: "10px",
                  }}
                >
                  {jobDetails?.location_name}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {jobDetails?.agent_id ? (
                <Link to={`/agentProfile/${jobDetails?.agent_id}`}>
                  <Cards
                    title="Agent"
                    name={`${jobDetails?.agent?.first_name} ${jobDetails?.agent?.last_name}`}
                    ratings={jobDetails?.agent?.total_ratings}
                    avatar={jobDetails?.agent?.avatar}
                  />
                </Link>
              ) : (
                <Cards title="Agent" name="NOT Assigned" />
              )}

              <div
                className="jobsPrice"
                style={{
                  padding: "10px",
                }}
              >
                <div
                  className="jobsPriceHeader"
                  style={{
                    fontSize: "12px",
                    color: "GrayText",
                    borderBottom: "1px dashed #efefef",
                  }}
                >
                  <span>Price</span>
                </div>
                <div
                  className="jobsPriceRate"
                  style={{
                    padding: "10px 0px",
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "GrayText",
                  }}
                >
                  <span>${millify(jobDetails?.proposed_cost ?? 0)}</span>
                </div>
              </div>
              <div
                className="jobsPrice"
                style={{
                  padding: "10px",
                }}
              >
                <div
                  className="jobsPriceHeader"
                  style={{
                    fontSize: "12px",
                    color: "GrayText",
                    borderBottom: "1px dashed #efefef",
                  }}
                >
                  <span>Amount Paid</span>
                </div>
                <div
                  className="jobsPriceRate"
                  style={{
                    padding: "10px 0px",
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "GrayText",
                  }}
                >
                  <span>${millify(jobDetails?.amount_paid ?? 0)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="jobDetails">
            <Tabs defaultActiveKey="1" type="card" size={"small"}>
              <TabPane tab="Description" key="1">
                <div
                  className="detailedDescription"
                  style={{
                    border: "1px solid #efefef",
                    padding: "12px",
                  }}
                >
                  <h3>About</h3>
                  <p>{jobDetails?.description}</p>
                  <h3>Skills</h3>
                  {jobDetails?.required_skills?.map((skill, index) => (
                    <Tag
                      key={index}
                      color="success"
                      style={{
                        marginBottom: "10px",
                        height: "fit-content",
                      }}
                    >
                      {skill}
                    </Tag>
                  ))}
                  <h3>Language</h3>
                  {jobDetails?.required_languages?.map((language, index) => (
                    <Tag
                      key={index}
                      color="orange"
                      style={{
                        marginBottom: "10px",
                        height: "fit-content",
                      }}
                    >
                      {language}
                    </Tag>
                  ))}
                </div>
                <div
                  className="paymentStatus"
                  style={{
                    padding: "12px",
                    marginTop: "30px",
                    width: "100%",
                  }}
                >
                  <JobSteps jobSteps={jobDetails?.payment_status} />

                  {
                    refunds?.length > 0 ? (

                      refunds?.map((refund, index) => {
                        return (
                          <div className="detailedDescription"
                            style={{
                              border: "1px solid #efefef",
                              padding: "12px",
                              marginTop: "10px"
                            }}>
                            <h4>Refund Details</h4>
                            <Card size="small" style={{ width: 200 }} key={index}>
                              <p>Refund Type: {refund.metadata.refund_type == "REFUNDED_FULL" ? 'Full' : 'Partial'}</p>
                              <p>Amount: ${refund.amount / 100}</p>
                              <p>Status: {showRefund ? 'refunded' : 'Inprocess'} </p>
                            </Card>
                          </div>
                        )

                      }
                      )

                    ) : ''
                  }





                </div>



              </TabPane>
              <TabPane tab="View Requests" key="2">
                <JobRequest id={jobDetails?.id} />
              </TabPane>
              <TabPane tab="View Proposals" key="3">
                <JobProposals id={jobDetails?.id} />
              </TabPane>
              <TabPane tab="View Disputes" key="4">
                <JobDisputes id={jobDetails?.id} />
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
}

export default JobDescription;
