import instanceBaseUrl from "./axios";

export const AllStats = async () => {
  const statsData = await instanceBaseUrl.get("/dashboard/");
  return statsData;
};

export const LeftChartData = async (start, end) => {
  const RevenueChartData = await instanceBaseUrl.get(
    `/dashboard/revenue_stats?start=${start}&end=${end}`
  );
  return RevenueChartData;
};
export const AllJobs = async (status, query, offset) => {
  console.log(status, query, "in jobs");
  const jobData = await instanceBaseUrl.get(
    `/job/all?${status && `job_status=${status}&`}page=${offset}&page_size=10${
      query && `&query=${query}`
    }`
  );
  return jobData;
};

export const CancelJobLink = async (id, refund_type) => {
  try {
    const respCancelJobLink = await instanceBaseUrl.post("/job/cancel", {
      job_id: String(id),
      refund_type: refund_type,
    });
    console.log(respCancelJobLink);
    return respCancelJobLink;
  } catch (error) {
    return error.response;
  }
};
//agents
export const AllAgents = async (status, rateType, query, offset) => {
  console.log(status, "rate");
  const allAgents = instanceBaseUrl.get("/agent/all", {
    params: {
      ...(status && { status: status }),
      ...(rateType && { rate: rateType }),
      page: offset,
      page_size: 10,
      query: query ?? "",
    },
  });
  return allAgents;
};

export const SingleAgent = async (id) => {
  console.log("singellllll", id);

  const singleAgentData = await instanceBaseUrl.get(`/agent/?agent_id=${id}`);
  const singleAgentJobs = await instanceBaseUrl.get(
    `/agent/job/all?agent_id=${id}`
  );
  console.log(singleAgentData);
  return {
    AgentData: singleAgentData,
    JobsPosted: singleAgentJobs,
  };
};

export const UpdateSingleAgent = async (
  id,
  first_name,
  last_name,
  description,
  email,
  phone,
  skills,
  topSkills,
  language,
  gender,
  countryCode,
  city,
  state,
  country,
  location,
  email_verified,
  level,
  phone_verified,
  status,
  availability,
  paypal_email,
  hourly_rate,
  fixed_rate
) => {
  try {
    const payload = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      description: description,
      email: email,
      phone: phone,
      skills: {
        all_skills: skills,
        top_skills: topSkills,
      },
      languages: language,
      gender: gender,
      country_code: countryCode,
      // address: address,
      city,
      state,
      country,
      location,
      email_verified: email_verified,
      level: level,
      phone_verified: phone_verified,
      account_status: {
        status: status,
      },
      availability: availability,
      paypal_email: paypal_email,
      hourly_rate: hourly_rate,
      fixed_rate: fixed_rate,
    };
    console.log("Payload", payload);

    const respUpdateSingleAgent = await instanceBaseUrl.put("/agent/", payload);
    console.log("response", respUpdateSingleAgent);
    return respUpdateSingleAgent;
  } catch (error) {
    return error.response;
  }
};
//Clients
export const SingleClient = async (id) => {
  try {
    const singleClientData = await instanceBaseUrl.get(
      `/client/?client_id=${id}`
    );
    const singleClientJobs = await instanceBaseUrl.get(
      `/client/job/all?client_id=${id}&page=1&page_size=50`
    );
    console.log(singleClientData);
    return {
      ClientData: singleClientData,
      JobsPosted: singleClientJobs,
    };
  } catch (e) {
    return e;
  }
};
//update client
export const UpdateSingleClient = async (
  id,
  first_name,
  last_name,
  description,
  email,
  phone,
  gender,
  countryCode,
  city,
  state,
  country,
  location,
  email_verified,
  level,
  phone_verified,
  status
) => {
  try {
    const payload = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      description: description,
      email: email,
      phone: phone,
      gender: gender,
      country_code: countryCode,
      city,
      state,
      country,
      location,
      email_verified: email_verified,
      level: level,
      phone_verified: phone_verified,
      account_status: {
        status: status,
      },
    };
    console.log("Client Payload", payload);
    const respUpdateSingleClient = await instanceBaseUrl.put(
      "/client/",
      payload
    );
    console.log("Client Update Response", respUpdateSingleClient);
    return respUpdateSingleClient;
  } catch (error) {
    return error.response;
  }
};
export const AllClients = async (status, query, offset) => {
  try {
    const resp = await instanceBaseUrl.get(
      `/client/all?${status && `status=${status}&`}page=${offset}&page_size=10${
        query && `&query=${query}`
      }`
    );
    return resp;
  } catch (e) {
    return e;
  }
};
//job api's

export const JobDescriptionData = async (id) => {
  console.log("in JObdescriptions");
  try {
    const JobDetails = await instanceBaseUrl.get(`/job/?job_id=${id}`);
    return JobDetails;
  } catch (error) {
    return error;
  }
};
//
export const AllJobProposals = async (id, offset) => {
  try {
    const JobProposalDetails = await instanceBaseUrl.get(
      `/job/proposals?job_id=${id}&page=${offset}&page_size=10`
    );
    console.log(JobProposalDetails);
    return JobProposalDetails;
  } catch (error) {
    return error;
  }
};

export const AllJobRequests = async (id, offset) => {
  try {
    const JobRequestDetails = await instanceBaseUrl.get(
      `/job/requests?job_id=${id}&page=${offset}&page_size=10`
    );
    return JobRequestDetails;
  } catch (error) {
    return error;
  }
};
export const AllJobDisputes = async (id, offset) => {
  try {
    const JobDisputesDetails = await instanceBaseUrl.get(
      `/job/disputes?job_id=${id}&page=${offset}&page_size=10`
    );
    return JobDisputesDetails;
  } catch (error) {
    return error;
  }
};

export const SingleJobDispute = async (id) => {
  try {
    const SingleJobDisputeDetails = await instanceBaseUrl.get(
      `/dispute/?dispute_id=${id}`
    );
    return SingleJobDisputeDetails;
  } catch (error) {
    return error.response;
  }
};

export const UpdateDispute = async (id, status) => {
  try {
    const respUpdateDispute = await instanceBaseUrl.put("dispute/", {
      id: id,
      status: status.toUpperCase(),
    });
    return respUpdateDispute;
  } catch (error) {
    return error.response;
  }
};
//post api's
export const AuthLogin = async (username, password) => {
  try {
    const resp = await instanceBaseUrl.post("/auth/login", {
      email: `${username}`,
      password: `${password}`,
    });
    return resp;
  } catch (e) {
    return e.response;
  }
};

export const AgentVerificationLink = async (id, status, reason) => {
  console.log(reason, "rejection reason");
  try {
    const respVerification = await instanceBaseUrl.post(
      `agent/verify?agent_id=${id}`,
      {
        status: `${status}`,
        rejection_reason: reason,
      }
    );
    console.log("Verification res", respVerification);
    return respVerification;
  } catch (error) {
    return error.response;
  }
};

//settings
export const ChargesData = async () => {
  try {
    const respChargesData = await instanceBaseUrl.get("/settings/");
    return respChargesData;
  } catch (e) {
    return e.response;
  }
};

export const UpdateChargesData = async (
  ea,
  es,
  di,
  cur,
  minPrice,
  maxPrice
) => {
  console.log(ea, es, di, cur);
  try {
    const respUpdateChargesData = await instanceBaseUrl.post("/settings/", {
      ea_charges: Number(ea),
      escrow_charges: Number(es),
      disburse_charges: Number(di),
      currency: cur,
      min_job_price: minPrice,
      max_job_price: maxPrice,
    });
    return respUpdateChargesData;
  } catch (err) {
    return err.response;
  }
};
export const GetSkills = async () => {
  try {
    const respGetSkills = await instanceBaseUrl.get("/skills/");
    return respGetSkills;
  } catch (error) {
    return error.response;
  }
};

export const UpdateSkills = async (data) => {
  try {
    const formData = new FormData();
    data.forEach((skill) => {
      if (skill) {
        formData.append("skills", skill);
      }
    });
    const respUpdateSkills = await instanceBaseUrl.post("/skills/", formData);
    return respUpdateSkills;
  } catch (error) {
    return error.response;
  }
};

export const UpdateProfile = async (email, password) => {
  try {
    const respUpdateProfile = await instanceBaseUrl.post("/auth/me", {
      email: `${email}`,
      password: `${password}`,
    });
    return respUpdateProfile;
  } catch (error) {
    return error.response;
  }
};

export const ContactUsList = (offset) => {
  const resp = instanceBaseUrl.get(
    `/contact-us/all?page=${offset}&page_size=10`
  );
  return resp;
};

export const getAllPlans = async ({ offset }) => {
  try {
    const resp = await instanceBaseUrl.get("/plans/all", {
      params: { page: offset, page_size: 10 },
    });
    return resp;
  } catch (error) {
    return error.response;
  }
};

export const getPlanById = async ({ id }) => {
  try {
    const resp = await instanceBaseUrl.get("/plans/", {
      params: { plan_id: id },
    });
    return resp;
  } catch (error) {
    return error.response;
  }
};

export const updatePlanById = async ({ values }) => {
  try {
    const resp = await instanceBaseUrl.put("/plans/", values);
    return resp;
  } catch (error) {
    console.log("type of error: ", JSON.stringify(error, null, 2));
    console.log("type of error.response: ", error.response);
    return error.response;
  }
};

export const getAllSubscribtions = async ({
  clientId,
  offset,
  planType,
  subscriptionType,
  startDate,
  endDate,
}) => {
  try {
    const resp = await instanceBaseUrl.get("/subscription/all", {
      params: {
        limit: 10,
        page: offset,
        client_id: clientId ?? "",
        ...(planType && { plan_type: planType }),
        ...(subscriptionType && { status: subscriptionType }),
        ...(startDate && endDate && { start_date: startDate }),
        ...(startDate && endDate && { end_date: endDate }),
      },
    });
    return resp;
  } catch (error) {
    return error.response;
  }
};

export const getSubscriptionStats = async () => {
  try {
    const resp = await instanceBaseUrl.get("/subscription/stats");
    return resp;
  } catch (error) {
    return error.response;
  }
};

export const cancelSubscription = async ({ values }) => {
  console.log(values, "cancel values");
  try {
    const resp = await instanceBaseUrl.post("/subscription/cancel", values);
    return resp;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getClientInvoices = async ({
  clientId,
  subscriptionId,
  offset,
  paymentStatus,
  startDate,
  endDate,
}) => {
  try {
    const resp = await instanceBaseUrl.get("/invoices/all", {
      params: {
        client_id: clientId ?? "",
        subscription_id: subscriptionId ?? "",
        limit: 10,
        page: offset,
        ...(paymentStatus && { status: paymentStatus }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
      },
    });
    return resp;
  } catch (error) {
    return error.response;
  }
};
