import React, { useState } from "react";
import { Button } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import "./JobsPosted.css";
import millify from 'millify'
import { Link } from "react-router-dom";
function JobsPosted({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  return !data ? (
    <div>no jobs POsted</div>
  ) : (
    <div
      className="jobsPosted"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "10px",
        marginBottom: "20px",
      }}
    >
      <div
        className="jobsHeader"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flex: "0.7",
        }}
      >
        <div className="jobsHeader__left" style={{}}>
          {/* <h2> Job Title</h2> */}
          <h3>{data.title}</h3>
          <p>{data.description}</p>
        </div>
        <div className="jobsHeader__right" style={{}}>
          <Button type="primary">
            <Link to={`/jobDescription/${data?.id}`}>View Job</Link>
          </Button>
        </div>
      </div>
      <div
        className="jobsFooter"
        style={{
          display: "flex",
          flex: "0.2",
          justifyContent: "space-between",
        }}
      >
        <div
          className="jobsFooter__left"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            height: " 100%",
          }}
        >
          <div className="left__header">
            <h3>
              Price :-
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "GrayText",
                }}
              >
                ${millify(data?.proposed_cost)}
              </span>{" "}
            </h3>
          </div>
        </div>
        <div
          className="jobsFooter__right"
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!data.job_status !== "OPEN" && (
            <div
              className="fulfilled"
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {data.agent ? (
                <>
                  <span>Agent Name</span>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "gray",
                    }}
                  >
                    {`${data?.agent?.first_name} ${data?.agent?.last_name}`}
                  </span>
                </>
              ) : (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "gray",
                  }}
                >
                  Not available
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobsPosted;
