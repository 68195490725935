import { Pagination, Table } from "antd";
// import millify from "millify";
import React, { useEffect, useState } from "react";
import { Input, Space } from "antd";
import { Link } from "react-router-dom";
import Tags from "../../Components/MeterComponents/Tags";
import { AllJobs } from "../../Services/apiClient";
import SelectFilterJob from "../../Components/Button/SelectFilterJob";
// import { useParams } from "react-router";

function JobLists() {
  // const { args } = useParams();
  const [jobs, setJobs] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(true);
  const [jobFilter, setJobFilter] = useState("");
  const [jobSearchValue, setJobSearchValue] = useState("");
  const [offset, setOffset] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(1);
  // console.log(args,"useParams");
  let mounted = true;
  useEffect(() => {
    mounted = true;
    console.log(jobFilter, "reaced");

    // args && setJobFilter(args)
    getJobData();
    return () => {
      mounted = false;
    };
  }, [jobFilter, jobSearchValue, offset]);

  const CheckStatus = (status) => {
    setOffset(1);
    setJobFilter(status);
  };

  const getJobData = async () => {
    console.log(jobFilter, "reached in call");
    setJobListLoading(true);
    try {
      const jobData = await AllJobs(
        jobFilter.toLocaleUpperCase(),
        jobSearchValue,
        offset
      );
      if (mounted) {
        setJobs(() => jobData?.data?.data?.jobs);
        setTotalRows(() => jobData?.data?.data?.count);
        setJobListLoading(false);
      }
    } catch (error) {
    } finally {
      setJobListLoading(false);
    }
  };

  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const columns = [
    {
      title: "Job ID",
      dataIndex: "id",
      render: (id, job) => {
        return (
          <Link to={`/jobDescription/${job.id}`}>
            <span>{id.slice(0, 8)}</span>{" "}
          </Link>
        );
      },
      responsive: ["lg"],
    },
    {
      title: "Job Title",
      dataIndex: "title",
      render: (jobtitle, job) => {
        return (
          <Link to={`/jobDescription/${job.id}`}>
            <span
              style={{
                wordBreak: "break-word",
              }}
            >
              {jobtitle}
            </span>{" "}
          </Link>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "job_status",
      render: (status) => {
        return <Tags status={status} />;
      },
    },
    {
      title: "Client",
      dataIndex: ["client", "first_name"],
      render: (client, job) => {
        return (
          <Link to={`/clientProfile/${job?.client?.id}`}>
            <span>{`${client} ${job?.client?.last_name}`}</span>{" "}
          </Link>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Agent",
      dataIndex: ["agent", "first_name"],
      render: (agent, job) => {
        return agent ? (
          <Link to={`/agentProfile/${job?.agent?.id}`}>
            <span>{`${agent} ${job?.agent?.last_name}`}</span>{" "}
          </Link>
        ) : (
          <span>{"Not assigned"}</span>
        );
      },
      responsive: ["md"],
    },
  ];

  const handlePress = (status) => {
    CheckStatus(status);
  };

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination);
  }
  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div
      className="jobLists"
      style={{
        margin: "0 auto",
        position: "relative",
        border: "1px solid #efefef",
      }}
    >
      <div className="innerJoblists" style={{}}>
        <div
          className="jobSearch"
          style={{
            display: "flex",
          }}
        >
          <div>
            <SelectFilterJob CheckStatus={CheckStatus} jobFilter={jobFilter} />
          </div>
          <div
            className="jobsearch__input"
            style={{
              width: "100%",
              flexGrow: "1",
            }}
          >
            <Space direction="vertical">
              <Search
                placeholder="Search Jobs Here "
                allowClear
                enterButton="Search"
                size="medium"
                onChange={(e) => {
                  // e.persist()

                  setJobSearchValue(e.target.value);
                  // _.debounce(getJobData(), 1000)
                  console.log(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Space>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={jobs}
          loading={jobListLoading}
          onChange={onChange}
          size={"medium"}
          pagination={false}
        />
        <div className="pagination">
          <Pagination
            total={totalRows}
            onChange={handlePageChange}
            showSizeChanger={false}
            showQuickJumper={false}
            defaultCurrent={1}
            current={offset}
          />
        </div>
      </div>
    </div>
  );
}

export default JobLists;
