import axios from "axios";

const instanceBaseUrl = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1`,
});
instanceBaseUrl.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instanceBaseUrl.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("dslkvnsdlvnsllknsvlknl");
    if (error?.response?.status == 403) {
      console.log("dslkvnsdlvnsllknsvlknl");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export default instanceBaseUrl;
