import React, { useState } from "react";

import { DatePicker, Pagination, Space, Table, Tag, message } from "antd";
import { useLocation, useParams } from "react-router-dom";
import SelectFilter from "./SelectFilter";
import { getClientInvoices } from "../../../Services/apiClient";
import moment from "moment";

const { RangePicker } = DatePicker;

const InvoicesList = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { isSubscription } = location?.state;

  const [invoices, setInvoices] = useState([]);
  const [offset, setOffset] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const paymentOptions = [
    "All",
    "draft",
    "uncollectible",
    "open",
    "paid",
    "void",
  ];

  const getInvoices = async () => {
    setLoading(true);
    const payment_status =
      paymentStatus === paymentOptions[0] ? undefined : paymentStatus;
    try {
      const resp = await getClientInvoices({
        ...(isSubscription ? { subscriptionId: id } : { clientId: id }),
        offset: offset,
        paymentStatus: payment_status,
        startDate: startDate,
        endDate: endDate,
      });
      const { data } = resp?.data;
      const { status } = resp;
      const { count } = resp?.data;

      if (status === 200) {
        setTotalRows(count);
        const invoicesArr = data?.map(
          ({ id, created, status, amount_paid, invoice_pdf }) => {
            return {
              key: id,
              date: moment.unix(created).format("MMM DD YYYY"),
              orderId: id,
              status: status,
              amount: `$${(amount_paid / 100)?.toFixed(2)}`,
              pdf: invoice_pdf,
            };
          }
        );
        setInvoices(invoicesArr);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      message.error(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Payment Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color = "green";
        if (status === "pending") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Action",
      key: "action",
      render: ({ pdf ,status }) => 
      status=='paid'?
        <Space size="middle">
          <a href={pdf} download={true}>
            <span>Download</span>
          </a>
        </Space>  :''
      ,
    },
  ];

  React.useEffect(() => {
    if (id !== undefined) {
      // console.log(id, "id");
      getInvoices();
    }
  }, [id, offset, paymentStatus, startDate, endDate]);

  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div>
      <div className="filters">
        <SelectFilter
          data={paymentOptions}
          placeholder="Payment Status"
          filter={paymentStatus}
          onChange={(value) => setPaymentStatus(value)}
        />
        <RangePicker
          format={"DD-MM-YYYY"}
          onChange={(date, dateStrings) => {
            if (date === null) {
              setStartDate(null);
              setEndDate(null);
            } else if (
              date?.length !== 0 &&
              date[0]?._d !== null &&
              date[1]?._d !== null
            ) {
              setStartDate(Math.floor(date[0]?._d?.setHours(0, 0, 0) / 1000));
              setEndDate(Math.floor(date[1]?._d?.setHours(23, 59, 59) / 1000));
            }
          }}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Table
          columns={columns}
          dataSource={invoices}
          pagination={false}
          loading={loading}
        />
        <div className="pagination">
          <Pagination
            total={totalRows}
            onChange={handlePageChange}
            showSizeChanger={false}
            showQuickJumper={false}
            defaultCurrent={1}
            current={offset}
            // defaultPageSize={2}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoicesList;
