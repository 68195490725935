import { Tag } from "antd";
import React from "react";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

function Tags(props) {
  const TagsTabs = () => {
    switch (props?.status?.toLowerCase()) {
      case "active":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            Active
          </Tag>
        );
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Completed
          </Tag>
        );
      case "agent_completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Agent Completed
          </Tag>
        );
      case "disputed":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Disputed
          </Tag>
        );
      case "suspended":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Suspended
          </Tag>
        );
      case "open":
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            Open
          </Tag>
        );
      case "inactive":
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            Inactive
          </Tag>
        );
      case "verified":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            verified
          </Tag>
        );

      case "unverified":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Unverified
          </Tag>
        );
      case "resolved":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Resolved
          </Tag>
        );
      case "unresolved":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Unresolved
          </Tag>
        );
      case "rejected":
        return (
          <Tag icon={<MinusCircleOutlined />} color="error">
            Rejected
          </Tag>
        );
      case "deleted":
        return (
          <Tag icon={<MinusCircleOutlined />} color="error">
            Deleted
          </Tag>
        );
      case "canceled":
        return (
          <Tag icon={<MinusCircleOutlined />} color="error">
            Canceled
          </Tag>
        );
        case "under_process":
          return (
            <Tag icon={<SyncOutlined spin />} color="default">
              Under Process
            </Tag>
          );

      default:
        return null;
    }
  };

  return (
    <div className="Tags">
      <TagsTabs />
    </div>
  );
}

export default Tags;
