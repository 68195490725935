import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useParams } from "react-router";
function SelectFilter({ CheckStatus, jobFilter }) {
  const { Option } = Select;
  const { args } = useParams();
  const [wid, setWid] = useState();
  
  useEffect(() => {
    console.log(args, "new method baby");
    args && CheckStatus(args)
  }, [args]);
  function onChange(value) {
    CheckStatus(value);
  }

  function onBlur() {}

  function onFocus() {}

  function onSearch(val) {
    console.log("search:", val);
  }
  return (
    <div>
      <Select
        showSearch
        style={{ width: wid , cursor : 'pointer'}}
        placeholder="Filter Jobs"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        defaultActiveFirstOption={true}
        value={jobFilter}
        dropdownStyle={{ minWidth: "200px" }}
      >
        <Option value="">All</Option>
        <Option value="OPEN">Open</Option>
        <Option value="ACTIVE">Active</Option>
        <Option value="AGENT_COMPLETED">Agent completed</Option>
        <Option value="CANCELED">Canceled</Option>
        <Option value="COMPLETED">Completed</Option>
        <Option value="DISPUTED">Disputed</Option>
        <Option value="DELETED">Deleted</Option>
      </Select>
    </div>
  );
}

export default SelectFilter;
