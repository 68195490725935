import { Layout, Menu, Drawer } from "antd";
import logo from "./Components/images/Logo.svg";
import ea from "./Components/images/ea.svg";
import DashboardHeader from "./Views/DashboardCharts/DashboardHeader";
import "./Container.css";
import {
  // BarChartOutlined,
  // ShopOutlined,
  TeamOutlined,
  // UserOutlined,
  // UploadOutlined,
  // VideoCameraOutlined,
  // AreaChartOutlined,
  SettingOutlined,
  ContactsOutlined,
  // PieChartOutlined,
  CarryOutOutlined,
  FundProjectionScreenOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import LeftChart from "./Views/DashboardCharts/LeftChart";
import RightChart from "./Views/DashboardCharts/RightChart";
import JobLists from "./Views/JobFilters/JobLists";
import ClientProfile from "./Views/Pages/ClientProfile/ClientProfile";
import AgentProfile from "./Views/Pages/AgentProfile/AgentProfile";
import AdminSetting from "./Views/Settings/Admin/AdminSetting";
import { Link, Switch, Route } from "react-router-dom";
import JobDescription from "./Views/Pages/JobDescription/JobDescription";
import AgentVerification from "./Views/Pages/AgentProfile/AgentVerification";
import StatsLeft from "./Views/DashboardCharts/StatsLeft";
import AgentList from "./Views/Pages/AgentProfile/AgentList";
import Contact from "./Views/Pages/Contact/Contact";
// import DemoScatter from "./Views/DashboardCharts/AllData";
// import DemoPie from "./Views/DashboardCharts/PieData";
// import Reports from "./Views/DashboardCharts/Reports";
import { useEffect, useState } from "react";
// import Demo from "./Components/UserInfo/BillingInfo/Bills";
import DisputesModal from "./Views/JobFilters/JobsDisputes/DisputesModal";
import ClientList from "./Views/Pages/ClientProfile/ClientList";
import { useLocation } from "react-router-dom";
import SubscriberList from "./Views/Pages/Subscriptions/SubscriberList";
import InvoicesList from "./Views/Pages/Subscriptions/InvoicesList";
import TotalRevenue from "./Views/Pages/Subscriptions/TotalRevenue";

function Container({ handleLogOut }) {
  const location = useLocation();
  const [key, setKey] = useState();
  const [cWidth, setCWidth] = useState(false);
  const { Content, Sider } = Layout;
  const [sider, setSider] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    window.innerWidth < 800 && setCWidth(true);
    window.addEventListener("resize", () => {
      if (window.innerWidth < 800) setCWidth(true);
      else setCWidth(false);
    });
    return window.removeEventListener("resize", () =>
      console.log("remove listenener")
    );
  }, [cWidth]);

  useEffect(() => {
    console.log(location.pathname.split("/")[1], "tufail");
    setKey(() => location.pathname.split("/")[1]);
  }, [location]);

  const SideHandler = () => {
    console.log(collapsed);
    if (window.innerWidth < 800) {
      setSider((prev) => !prev);
    } else {
      setCollapsed((prev) => !prev);
    }
  };
  const AutoSideHandler = () => {
    if (window.innerWidth > 1200) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  };
  // console.log(sider, "meri jaan");
  const SideMobileHandle = () => {
    setSider((prev) => !prev);
  };
  return (
    <Layout>
      {cWidth ? (
        <Drawer
          placement={"left"}
          visible={sider}
          bodyStyle={{
            padding: "24px 0px",
          }}
          // closable={false}
          onClose={() => setSider((prev) => !prev)}
          width="175px"
        >
          <Sider
            trigger={null}
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            style={{
              backgroundColor: "white",
              minWidth: "174px",
            }}
          >
            <div
              className="user"
              style={{
                padding: "25px",
                paddingTop: "40px",
                maxWidth: "175px",
              }}
            >
              <Link to="/">
                <img src={logo} alt="EveryWhere Agent"></img>
              </Link>
            </div>
            <Menu
              mode="inline"
              // defaultSelectedKeys={["/"]}
              selectedKeys={key || "/"}
              // defaultOpenKeys={["sub1"]}
              inlineCollapsed={collapsed}
            >
              <Menu.Item key="/" icon={<FundProjectionScreenOutlined />}>
                <Link to="/">Dashboard</Link>
              </Menu.Item>

              <Menu.Item key="jobs" icon={<CarryOutOutlined />}>
                <Link to="/jobs/">Jobs</Link>
              </Menu.Item>
              <Menu.Item key="agents" icon={<UserAddOutlined />}>
                <Link to="/agents">Agents</Link>
              </Menu.Item>
              <Menu.Item key="clients" icon={<TeamOutlined />}>
                <Link to="/clients">Clients</Link>
              </Menu.Item>
              {/* <Menu.Item key="subscriptions" icon={<TeamOutlined />}>
                <Link to="/subscriptions">subscriptions</Link>
              </Menu.Item> */}
              <Menu.Item key="Settings" icon={<SettingOutlined />}>
                <Link to="/Settings">Settings</Link>
              </Menu.Item>
              <Menu.Item key="Messages" icon={<ContactsOutlined />}>
                <Link to="/Messages">Queries</Link>
              </Menu.Item>
              {/* <Menu.Item key="6" icon={<AppstoreOutlined />}>
               <Link to='/clientProfile'>
               ClientProfile
               </Link>
              </Menu.Item> */}

              {/* <Menu.Item key="6" icon={<ShopOutlined />}>
                <Link to="/Bills">Bills</Link>
              </Menu.Item> */}
            </Menu>
          </Sider>
        </Drawer>
      ) : (
        <Sider
          breakpoint="xl"
          collapsedWidth={"80"}
          collapsible
          width={175}
          trigger={null}
          collapsed={collapsed}
          onBreakpoint={(broken) => {
            console.log(broken, "Vvfv");
            AutoSideHandler();
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          style={{
            backgroundColor: "white",
            // minWidth: "180px",
            // maxWidth: "184px",
          }}
        >
          <div
            className="user"
            style={{
              padding: "25px",
              paddingTop: "40px",
              maxWidth: collapsed ? "100px" : "100%",
            }}
          >
            <Link to="/">
              <img
                src={collapsed ? ea : logo}
                alt="EveryWhere Agent"
                style={{ width: collapsed ? "75%" : "100%" }}
              ></img>
            </Link>
          </div>
          <Menu
            mode="inline"
            // defaultSelectedKeys={key || '/'}
            selectedKeys={key || "/"}
            // defaultOpenKeys={["sub1"]}
            inlineCollapsed={collapsed}
            style={{ maxWidth: collapsed ? "80px" : "100%" }}
          >
            <Menu.Item key="/" icon={<FundProjectionScreenOutlined />}>
              <Link to="/">Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="jobs" icon={<CarryOutOutlined />}>
              <Link to="/jobs">Jobs</Link>
            </Menu.Item>
            <Menu.Item key="agents" icon={<UserAddOutlined />}>
              <Link to="/agents">Agents</Link>
            </Menu.Item>
            <Menu.Item key="clients" icon={<TeamOutlined />}>
              <Link to="/clients">Clients</Link>
            </Menu.Item>
            {/* <Menu.Item key="subscriptions" icon={<TeamOutlined />}>
              <Link to="/subscriptions">Subscriptions</Link>
            </Menu.Item> */}
            <Menu.Item key="Settings" icon={<SettingOutlined />}>
              <Link to="/Settings">Settings</Link>
            </Menu.Item>
            <Menu.Item key="Messages" icon={<ContactsOutlined />}>
              <Link to="/Messages">Queries</Link>
            </Menu.Item>
            {/* <Menu.Item key="/Bills" icon={<ShopOutlined />}>
              <Link to="/Bills">Bills</Link>
            </Menu.Item> */}
          </Menu>
        </Sider>
      )}

      <Layout className="site-layout" style={{}}>
        <DashboardHeader
          SideHandler={SideHandler}
          handleLogOut={handleLogOut}
        />
        <Content
          style={{
            margin: "auto",
            marginTop: "16px",
            minHeight: "100vh",
            height: "100%",
            width: "96%",
            maxWidth: "1700px",
            overflow: "initial",
          }}
        >
          <Switch>
            <Route exact path="/">
              <div className="body-layout" style={{}}>
                <div className="upperLayout" style={{}}>
                  <div
                    className="site-layout-background layout-leftChart"
                    style={{
                      padding: 10,
                      backgroundColor: "white",
                      position: "relative",
                    }}
                  >
                    <LeftChart />
                  </div>

                  <div
                    className="site-layout-background layout-rightChart"
                    style={{}}
                  >
                    <RightChart />
                  </div>
                </div>
                <div
                  className="statss"
                  style={{
                    width: "100%",
                  }}
                >
                  <StatsLeft />
                </div>
              </div>
            </Route>
            <Route path="/jobs/:args?">
              <JobLists />
            </Route>
            <Route path="/agents/:args?">
              <AgentList />
            </Route>
            <Route path="/clients/:args?">
              <ClientList />
            </Route>
            <Route path="/subscriptions">
              <SubscriberList />
            </Route>
            <Route path="/clientProfile/:id">
              <ClientProfile />
            </Route>
            <Route path="/agentProfile/:id">
              <AgentProfile />
            </Route>
            <Route path="/Settings">
              <AdminSetting />
            </Route>
            <Route exact path="/jobDescription/:id">
              <JobDescription />
            </Route>
            <Route path="/jobDescription/:id/dispute/:dispute">
              <DisputesModal />
            </Route>
            <Route path="/Messages">
              <Contact />
            </Route>
            <Route path="/invoices/:id">
              <InvoicesList />
            </Route>
            <Route path="/total-revenue">
              <TotalRevenue />
            </Route>

            {/* <Route path="/Bills">
              <Demo />
            </Route> */}
            <Route path="/agentVerification/:id">
              <AgentVerification />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Container;
