import React, { useState } from "react";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Pagination,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import { green, gold, red, grey, blue } from "@ant-design/colors";

import "./subscriberList.css";
import SelectFilter from "./SelectFilter";
import InputModal from "./InputModal";
import { Link, useHistory } from "react-router-dom";
import {
  getAllPlans,
  getAllSubscribtions,
  getSubscriptionStats,
  updatePlanById,
} from "../../../Services/apiClient";
import moment from "moment";
import CancelModal from "./CancelModal";
import { Circles } from "react-loader-spinner";

const { RangePicker } = DatePicker;

const SubscriberList = () => {
  const [selectedPlanType, setSelectedPlanType] = useState("All");
  const [selectedSubscription, setSelectedSubscription] = useState("all");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [open, setOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const history = useHistory();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [offset, setOffset] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [stats, setStats] = useState({
    activeSubscribers: 0,
    revenue: 0,
  });

  const planTypes = {
    country: "INTERNATIONAL",
    state: "NATIONAL",
    city: "STATE",
  };
  const planVisibility = {
    visible: "VISIBLE",
    inVisible: "NOT_VISIBLE",
  };

  const planOptions = ["All", "State", "National", "International"];
  const subscriptionOptions = [
    "all",
    "active",
    "past",
    "due",
    "unpaid",
    "canceled",
    "incomplete",
    "trialing",
    "ended",
  ];

  const getPlans = async () => {
    setLoading(true);
    try {
      const resp = await getAllPlans({ offset: 1 });
      const { data } = resp?.data;
      const { status } = resp;

      if (status === 200) {
        setPlans(data?.plans);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getSubscriptions = async () => {
    setTableLoading(true);
    const planType =
      selectedPlanType === planOptions[0]
        ? undefined
        : selectedPlanType === planOptions[1]
        ? planTypes.city
        : selectedPlanType === planOptions[2]
        ? planTypes.state
        : selectedPlanType === planOptions[3]
        ? planTypes.country
        : undefined;
    console.log(planType, "plantype");
    try {
      const resp = await getAllSubscribtions({
        clientId: "",
        offset: offset,
        planType: planType,
        subscriptionType: selectedSubscription,
        startDate: startDate,
        endDate: endDate,
      });
      const { data } = resp?.data;
      const { count } = resp?.data;
      const { status } = resp;

      if (status === 200) {
        console.log(count, "count");
        setTotalRows(count);
        const subscribersArr = data?.map(
          ({
            id,
            current_period_start,
            current_period_end,
            metadata,
            status,
            customer,
          }) => {
            return {
              key: id,
              name: `${metadata?.client_first_name ?? "-"} ${
                metadata?.client_last_name ?? "-"
              } `,
              planType: metadata?.plan_name ?? "--",
              startDate: moment
                .unix(current_period_start)
                .format("MMM DD YYYY"),
              renewalDate: moment
                .unix(current_period_end)
                .format("MMM DD YYYY"),
              status: status,
              subId: id,
            };
          }
        );
        setSubscribers(subscribersArr);
        setTableLoading(false);
      }
    } catch (err) {
      console.log(err);
      setTableLoading(false);
    } finally {
      setTableLoading(false);
    }
  };

  const updatePlan = async (id, checked) => {
    setLoading(true);
    const values = {
      id: id,
      visibility: checked ? planVisibility.visible : planVisibility.inVisible,
    };
    try {
      const res = await updatePlanById({ values: values });
      const { status } = res?.data;
      if (status === 200) {
        message.success("Plan Updated Successfully");
      } else if (status === 400) {
        message.error("Cannot Update. Plan has some subscribers");
      } else {
        message.error("Something Went Wrong");
      }
      setLoading(false);
      setRefresh((prev) => !prev);
    } catch (err) {
      console.log(err);
      message.error(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getStats = async () => {
    try {
      const resp = await getSubscriptionStats();
      const { data } = resp;
      const { status } = resp;
      if (status === 200) {
        setStats({
          activeSubscribers: data?.active_subscribers,
          revenue: data?.revenue,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
      key: "planType",
    },
    {
      title: "Subscription Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color = "green";
        if (
          status === "canceled" ||
          status === "incomplete" ||
          status === "expired"
        ) {
          color = "volcano";
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "StartDate",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "Renewal Date",
      dataIndex: "renewalDate",
      key: "renewalDate",
    },

    {
      title: "Action",
      key: "action",
      render: ({ subId, key }) => (
        <Space size="middle">
          <Link
            to={{
              pathname: "/invoices/" + subId,
              state: { isSubscription: true },
            }}
          >
            <span>View Invoice</span>
          </Link>
          <p
            style={{ color: red[5], marginBottom: 0, cursor: "pointer" }}
            onClick={() => {
              setOpenCancelModal(true);
              setSubscriptionId(key);
            }}
          >
            Cancel
          </p>
        </Space>
      ),
    },
  ];

  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  React.useEffect(() => {
    getSubscriptions();
  }, [
    open,
    openCancelModal,
    offset,
    refresh,
    selectedPlanType,
    selectedSubscription,
    startDate,
    endDate,
  ]);

  React.useEffect(() => {
    getPlans();
    getStats();
  }, [open, refresh]);

  return loading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Circles
        height="40"
        width="40"
        color={blue[5]}
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  ) : (
    <div className="site-card-wrapper">
      <Row gutter={8}>
        <Col flex="225px">
          <Card style={{ minHeight: "170px" }}>
            <Statistic
              title="Active Subscribers"
              value={stats?.activeSubscribers}
              precision={0}
              valueStyle={{ color: grey[7] }}
            />
          </Card>
        </Col>

        <Col flex="225px">
          <Card style={{ minHeight: "170px" }}>
            <Statistic
              title="Total Revenue"
              value={`$${stats?.revenue?.toFixed(2)}`}
              valueStyle={{ color: grey[7] }}
              formatter={(value) => <p>{value}</p>}
            />
            <Button
              type="primary"
              onClick={() => history.push("/total-revenue")}
            >
              View
            </Button>
          </Card>
        </Col>
        {plans?.map((plan) => {
          return (
            <Col flex="230px">
              <Card style={{ minHeight: "170px" }}>
                <Statistic
                  title={plan.plan_name ?? "--"}
                  value={
                    `$${(plan.amount - plan.discount_amount)?.toFixed(2)}` ??
                    `$0`
                  }
                  valueStyle={{ color: grey[7] }}
                  formatter={(value) => (
                    <div className="value_formatter">
                      <p className="original_rate">
                        {`$${plan.amount?.toFixed(2)}` ?? `$0`}
                      </p>
                      <p>{value}</p>
                    </div>
                  )}
                />
                <div className="bottom-container">
                  <Button
                    style={{
                      backgroundColor:
                        plan?.plan_type === planTypes.city
                          ? blue[5]
                          : plan?.plan_type === planTypes.state
                          ? gold[5]
                          : green[6],
                      color: "#fff",
                    }}
                    onClick={() => {
                      setOpen(true);
                      setSelectedPlan(plan?.id);
                    }}
                  >
                    Edit Rate
                  </Button>
                  <Switch
                    defaultChecked={
                      plan?.visibility === planVisibility.visible ? true : false
                    }
                    onChange={(checked) => updatePlan(plan?.id, checked)}
                  />
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>
          <div className="filters">
            <div className="filters_children">
              <SelectFilter
                data={planOptions}
                placeholder="Plan Type"
                filter={selectedPlanType}
                onChange={(e) => {
                  console.log(e, "plan type value");
                  setSelectedPlanType(e);
                }}
              />
              <SelectFilter
                data={subscriptionOptions}
                placeholder="Subscription Status"
                filter={selectedSubscription}
                onChange={(value) => setSelectedSubscription(value)}
              />
            </div>
            <div>
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={(date, dateStrings) => {
                  if (date === null) {
                    setStartDate(null);
                    setEndDate(null);
                  } else if (
                    date?.length !== 0 &&
                    date[0]?._d !== null &&
                    date[1]?._d !== null
                  ) {
                    setStartDate(
                      Math.floor(date[0]?._d?.setHours(0, 0, 0) / 1000)
                    );
                    setEndDate(
                      Math.floor(date[1]?._d?.setHours(23, 59, 59) / 1000)
                    );
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={subscribers}
            pagination={false}
            loading={tableLoading}
          />
        </Col>
        <div className="pagination">
          <Pagination
            total={totalRows}
            onChange={handlePageChange}
            showSizeChanger={false}
            showQuickJumper={false}
            defaultCurrent={1}
            current={offset}
            // defaultPageSize={2}
          />
        </div>
      </Row>
      <InputModal
        open={open}
        planId={selectedPlan}
        handleOk={() => setOpen(false)}
        handleCancel={() => setOpen(false)}
      />
      <CancelModal
        open={openCancelModal}
        subscriptionId={subscriptionId}
        handleOk={() => setOpenCancelModal(false)}
        handleCancel={() => setOpenCancelModal(false)}
      />
    </div>
  );
};

export default SubscriberList;
