import "./App.css";
import Container from "./Container";
import Login from "./Views/Login/Login";
import { BrowserRouter as Router } from "react-router-dom";
import { useState, useEffect } from "react";
import { Detector, Offline, Online } from "react-detect-offline";
import NotConnected from "./Components/UserInfo/NetworkError/NotConnected";

function App() {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const checkingUser = localStorage.getItem("user");
    console.log(checkingUser);
    if (checkingUser) handleLogged();
    else handleLogOut();
  }, []);

  const handleLogged = () => {
    setIsLogged(true);
  };

  const handleLogOut = () => {
    localStorage.clear();
    setIsLogged(false);
  };

  // const polling = {
  //   enabled: true,
  //   interval: 600000,
  //   timeout: 7000,
  //   url: "https://www.google.com",
  // };

  return (
    <div className="app">
      <Router>
        {!isLogged ? (
          <Login handleLogged={handleLogged} />
        ) : (
          <Container handleLogOut={handleLogOut} />
        )}
      </Router>
    </div>
  );
}

export default App;
