import React, { useState, useEffect } from "react";
import "./LeftChart.css";
import { Column } from "@ant-design/charts";
import { AllStats } from "../../Services/apiClient";

const RightChart = () => {
  const [dataa, setDataa] = useState([]);
  useEffect(() => {
    getChartData();
  }, []);

  const getChartData = async () => {
    let a = [];
    try {
      const resp = await AllStats();
      setDataa(resp.data.data);
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(data);
  var data = [
    {
      name: "Verified Clients",
      month: "Activity",
      value: dataa?.verified_clients || 0,
      type: "verified Clients",
    },
    {
      name: "Verified Agents",
      month: "Activity",
      value: dataa?.verified_agents || 0,
      type: "verified agents",
    },
    {
      name: "Unverified Agents",
      month: "Activity",
      value: dataa?.unverified_agents || 0,
      type: "unverified agents",
    },
    {
      name: "Open Jobs",
      month: "Activity",
      value: dataa?.open_jobs || 0,
      type: "open jobs",
    },
    {
      name: "Disputed Jobs",
      month: "Activity",
      value: dataa?.disputed_jobs || 0,
      type: "disputed",
    },
    {
      name: "Completed Jobs",
      month: "Activity",
      value: dataa?.completed_jobs || 0,
      type: "completed",
    },
    {
      name: "Canceled Jobs",
      month: "Activity",
      value: dataa?.canceled_jobs || 0,
      type: "canceled",
    },
    {
      name: "Active Jobs",
      month: "Activity",
      value: dataa?.active_jobs || 0,
      type: "active jobs",
    },
    {
      name: "Under Process Agents",
      month: "Activity",
      value: dataa?.under_process_agents || 0,
      type: "under process agents"
    }
  ];
  var config = {
    data: data,
    xField: "month",
    yField: "value",
    isGroup: true,
    isStack: true,
    seriesField: "type",
    groupField: "name",
  };
  return (
    <div className="rightChartContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <h3
          style={{
            fontSize: "16px",
            fontWeight: "700",
            marginBottom: "15px",
            color: "rgb(98 97 97)",
          }}
        >
          Statistics
        </h3>
        {/* <RangePicker
          defaultValue={[
            moment("2021/01/01", "YYYY/MM/DD"),
            moment("2022/01/01", "YYYY/MM/DD"),
          ]}
        className="picker"
          // size="small"
          // onChange={onChange}
          bordered={false}
        /> */}
      </div>
      <Column {...config} tooltip={false} />
    </div>
  );
};

export default RightChart;
