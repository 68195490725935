import { Select } from "antd";
import React from "react";

const SelectFilter = ({ onChange, data, placeholder, filter }) => {
  const { Option } = Select;
  return (
    <div>
      <Select
        style={{ cursor: "pointer", minWidth: "150px" }}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        value={filter}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        dropdownStyle={{ minWidth: "150px" }}
      >
        {data?.map((el) => {
          console.log(typeof el);
          return (
            <Option value={el}>
              {el?.charAt(0).toUpperCase() + el?.slice(1)}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectFilter;
