import { Badge, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import millify from "millify";
import { Input, Space } from "antd";
import { Link } from "react-router-dom";
// import JobsListDate from "../../../Components/DateForLists/JobsListDate";
import SelectFilter from "../../../Components/Button/SelectFilter";
import Tags from "../../../Components/MeterComponents/Tags";
import { AllClients } from "../../../Services/apiClient";
import { gold, green } from "@ant-design/colors";

function ClientList() {
  const [clientList, setClientList] = useState([]);
  const [clientStatusValue, setClientStatusValue] = useState("ALL");
  const [clientSearchValue, setClientSearchValue] = useState("");
  const [clientListLoader, setClientListLoader] = useState(true);
  const [offset, setOffset] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(1);
  const data = [
    "ALL",
    "SUSPENDED",
    "UNVERIFIED",
    "VERIFIED",
    "UNDER_PROCESS",
    "DEACTIVATED",
    "DELETED",
  ];
  let mounted = true;

  const planType = {
    NONE: "No Plan",
    STATE: "State Level Subscription",
    NATIONAL: "National Level Subscription",
    INTERNATIONAL: "International Level Subscription",
  };

  useEffect(() => {
    getClientList();
    return () => {
      mounted = false;
    };
  }, [clientSearchValue, clientStatusValue, offset]);

  const getClientList = async () => {
    setClientListLoader(true);
    try {
      const resp = await AllClients(
        clientStatusValue === "ALL"
          ? ""
          : clientStatusValue.toLocaleUpperCase(),
        clientSearchValue,
        offset
      );
      mounted && setClientList(resp?.data?.data?.clients);
      setTotalRows(resp?.data?.data?.count);
    } catch (e) {
      console.log(e);
    } finally {
      mounted && setClientListLoader(false);
    }
  };

  console.log(clientList);
  const getData = (value) => {
    setOffset(1);
    setClientStatusValue(value);
  };

  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const columns = [
    {
      title: "Client ID",
      dataIndex: "id",
      render: (id, client) => {
        return (
          <Link to={`/clientProfile/${client.id}`}>
            <span>{id.slice(0, 6)}</span>
          </Link>
        );
      },
      responsive: ["lg"],
    },
    {
      title: "Client Name",
      dataIndex: "first_name",
      render: (name, client) => {
        return (
          <Link to={`/clientProfile/${client.id}`}>
            <span>{`${name} ${client.last_name}`} </span>
          </Link>
        );
      },
    },
    {
      title: "client Status",
      dataIndex: ["account_status", "status"],
      render: (status) => {
        console.log("ddee", status);
        return <Tags status={status} />;
      },
    },
    {
      title: "Jobs posted",
      dataIndex: "jobs_posted",
      responsive: ["sm"],
    },
    {
      title: "Spent",
      dataIndex: "total_spent",
      render: (spent) => {
        return <span>$ {millify(spent)}</span>;
      },
      responsive: ["md"],
    },
    // {
    //   title: "Subscription",
    //   dataIndex: "plan_type",
    //   render: (plan_type) => {
    //     return (
    //       <Badge
    //         count={planType[plan_type]}
    //         size="default"
    //         style={{
    //           backgroundColor:
    //             planType[plan_type] === planType.NONE ? gold[4] : green[4],
    //           minWidth: "96px",
    //           minHeight: "24px",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       />
    //     );
    //   },
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div className="agentList">
      <div
        className="agentContainer"
        style={{
          margin: "0 auto",
          position: "relative",
          border: "1px solid #efefef",
        }}
      >
        <div className="innerJoblists" style={{}}>
          <div
            className="jobSearch"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div className="selectFilter" style={{}}>
              <SelectFilter
                getData={getData}
                placeholder="Filter Clients"
                jobFilter={clientStatusValue}
                data={data}
              />
            </div>
            <div
              className="jobsearch__input"
              style={{
                flex: 2,
                width: "100%",
              }}
            >
              <Space direction="vertical">
                <Search
                  placeholder="Search Clients Here"
                  allowClear
                  enterButton="Search"
                  size="medium"
                  onSearch={onSearch}
                  onChange={(e) => setClientSearchValue(e.target.value)}
                />
              </Space>
            </div>
            {/* <div className="date__picker" style={{ flex: 1 }}>
              <JobsListDate />
            </div> */}
          </div>
          <Table
            columns={columns}
            loading={clientListLoader}
            dataSource={clientList}
            onChange={onChange}
            pagination={false}
          />
          <div className="pagination">
            <Pagination
              total={totalRows}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
              defaultCurrent={1}
              current={offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientList;
