export function getExtension(filename) {
  console.log(filename);
  var parts = filename?.split(".");
  return parts[parts?.length - 1];
}

export function isImage(filename) {
  var ext = getExtension(filename);
  console.log("me in", filename, ext);
  switch (ext?.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
    case "jpeg":
      //etc
      return true;
  }
  return false;
}

export function isPdf(filename) {
  console.log(filename);
  var ext = getExtension(filename);
  switch (ext?.toLowerCase()) {
    case "pdf":
    case "doc":
    case "xml":
    case "docx":
      return true;
  }
  return false;
}
