import React, { useState } from "react";
import { Skeleton, Card, Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { blue, gold, green, yellow } from "@ant-design/colors";
import "./AvatarProfile.css";
import millify from "millify";

function AvatarProfile(props) {
  const planType = {
    NONE: "No Plan",
    STATE: "State Level Subscription",
    NATIONAL: "National Level Subscription",
    INTERNATIONAL: "International Level Subscription"
  };

  const [loading, setLoading] = useState(false);
  const { Meta } = Card;
  console.log(props, "props");

  const AgentDescription = () => {
    return (
      <div className="description">
        <p>{`Location : ${
          props?.location ? props?.location : "Not available"
        }`}</p>
        <div className="price-container">
          {props?.hourlyRate > 0 || props?.fixedRate > 0 ? (
            <>
              <Badge
                count={`$${millify(props?.hourlyRate)}/hour`}
                size="default"
                style={{
                  backgroundColor: blue[1],
                  color: blue[6],
                  minWidth: "96px",
                  minHeight: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Badge
                count={`$${millify(props?.fixedRate)}/job`}
                size="default"
                style={{
                  backgroundColor: yellow[3],
                  color: yellow[8],
                  minWidth: "96px",
                  minHeight: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </>
          ) : (
            <Badge
              count={`Open to offers`}
              size="default"
              style={{
                backgroundColor: yellow[3],
                color: yellow[8],
                minWidth: "96px",
                minHeight: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const ClientDescription = () => {
    return (
      <div className="description">
        <p>{`Location : ${
          props?.location ? props?.location : "Not available"
        }`}</p>
        <Badge
          count={planType[props?.planType]}
          size="default"
          style={{
            backgroundColor:
              planType[props?.planType] === planType.NONE ? gold[4] : green[4],
            minWidth: "96px",
            minHeight: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
    );
  };

  return (
    <div
      className="avatarProfile"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Card
        style={{
          width: "100%",
          border: "none",
          padding: "1",
        }}
      >
        <Skeleton loading={loading} avatar active>
          <Meta
            style={{
              display: "flex",
              height: "200px",

              // border: "1px solid #efefef",
              // boxShadow: "0px 0px 5px #efefef",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "0",
              textAlign: "center",
              position: "relative",
            }}
            avatar={
              <Avatar
                size={100}
                shape={`${props.shape}`}
                src={`${process.env.REACT_APP_MINIO_URL}${props.avatarSrc}`}
                icon={<UserOutlined />}
                onError={() => {
                  return <UserOutlined />;
                }}
              />
            }
            title={`Name :- ${props?.name}`}
            description={
              props?.isClient ? <ClientDescription /> : <AgentDescription />
            }
          />
        </Skeleton>
      </Card>
    </div>
  );
}

export default AvatarProfile;
