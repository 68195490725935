import React, { useState } from "react";
import { Modal, Button, message, Result } from "antd";
import DisputeDetails from "./DisputeDetails";
import CancelJob from "./CancelJob";
import { useHistory, useParams } from "react-router";
import { CancelJobLink, UpdateDispute } from "../../../Services/apiClient";
const DisputesModal = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const history = useHistory();
  const [jobResolved, setJobresolved] = useState(false);

  const text = "Are you sure you want to resolve";
  const [jobStatus, SetJobStatus] = useState("cancel");
  const [showResolveButton, setShowResolveButton] = useState(false);
  const [cancelJobVisible, setCancelJobVisible] = useState(false);
  const { id, dispute } = useParams();
  const showModal = () => {
    setVisible(true);
  };
  const showCancelJobModal = () => {
    setCancelJobVisible(true);
  };
  const disableButton = () => {
    setShowResolveButton(true);
  };

  const handleOk = (jstatus) => {
    setConfirmLoading(true);
    (async function (params) {
      const respResolved = await UpdateDispute(dispute, jstatus);
      console.log("FFEF", respResolved);

      if (respResolved?.data?.status == 200) {
        setJobresolved(true);
        SetJobStatus(jstatus);
      } else {
        message.error("Problem resolving the JOB");
      }
    })();

    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      setTimeout(() => {
        history.push(`/jobDescription/${id}`);
      }, 2000);
    }, 2000);
  };
  const handleOkk = (jstatus) => {
    setConfirmLoading(true);
    (async function (params) {
      const respResolved = await CancelJobLink(id, jstatus);
      console.log("cancel job status", respResolved);
      console.log(jstatus);

      if (respResolved?.data?.status == 200) {
        setJobresolved(false);
        SetJobStatus("cancel");
        setTimeout(() => {
          setConfirmLoading(false);
          setCancelJobVisible(false);
          setJobresolved(true);
          setTimeout(() => {
            history.push(`/jobDescription/${id}`);
          }, 2000);
        }, 2000);
      } else {
        setTimeout(() => {
          setConfirmLoading(false);
          setCancelJobVisible(false);
        }, 1000);
        message.error(respResolved?.data?.error || "Network issues", 2.5);
      }
    })();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  const handleCancell = () => {
    console.log("Clicked cancel button");
    setCancelJobVisible(false);
  };

  return (
    <div
      className="DisputesModal"
      style={{
        backgroundColor: "white",
        padding: "30px",
        width: "95%",
        margin: "0 auto",
      }}
    >
      {jobResolved ? (
        <div className="resolved">
          <Result
            status={jobStatus == "cancel" ? "info" : "success"}
            title={
              jobStatus == "cancel"
                ? "Successfully Cancelled This Job "
                : "Succesfully Resolved this Job"
            }
            subTitle="Job ID: 2017182818828182881 Returning in  5 seconds.. , please wait."
          />
        </div>
      ) : (
        <>
          <div className="details">
            <DisputeDetails disableButton={disableButton} />
          </div>
          <div
            className="detailsButtons"
            style={{
              marginTop: "20px",
              width: "100%",

              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
            }}
          >
            <CancelJob
              handleOkk={handleOkk}
              handleCancel={handleCancell}
              cancelJobVisible={cancelJobVisible}
              confirmLoading={confirmLoading}
              showResolveButton={showResolveButton}
              showCancelJobModal={showCancelJobModal}
            />
            <Button
              disabled={showResolveButton}
              type="primary"
              onClick={showModal}
            >
              Resolve
            </Button>
            <Modal
              title="Title"
              visible={visible}
              okText="Yes"
              onOk={() => handleOk("RESOLVED")}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <p>{text}</p>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default DisputesModal;
