import { Input, message, Radio, Select, Tag } from "antd";
import { Option } from "antd/lib/mentions";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { SingleClient, UpdateSingleClient } from "../../../Services/apiClient";
import Autocomplete from "react-google-autocomplete";

function EditClientProfile(props) {
  useEffect(() => {
    console.log(props, "edit");
    getEditableData();
  }, [props]);

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
  });
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [emailVerified, setEmailVerified] = useState(Boolean);
  const [clientLevel, setClientLevel] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(Boolean);
  const [clientAccountStatus, setClientAccountStatus] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  const [location, setLocation] = useState([]);

  const getEditableData = async () => {
    const formData = await SingleClient(props.id);
    console.log("form data client", formData);
    setFName(formData?.ClientData?.data?.data?.first_name);
    setLName(formData?.ClientData?.data?.data?.last_name);
    setDescription(formData?.ClientData?.data?.data?.description);
    setEmail(formData?.ClientData?.data?.data?.email);
    setPhone(formData?.ClientData?.data?.data?.phone);
    setGender(formData?.ClientData?.data?.data?.gender);
    setCountryCode(formData?.ClientData?.data?.data?.country_code);
    // setAddress({ city: formData?.ClientData?.data?.data?.city, state: formData?.ClientData?.data?.data?.state, country: formData?.ClientData?.data?.data?.country });
    setDefaultAddress(
      `${formData?.ClientData?.data?.data?.city} ${formData?.ClientData?.data?.data?.state} ${formData?.ClientData?.data?.data?.country}`
    );
    setEmailVerified(formData?.ClientData?.data?.data?.email_verified);
    setClientLevel(formData?.ClientData?.data?.data?.level);
    setPhoneVerified(formData?.ClientData?.data?.data?.phone_verified);
    setClientAccountStatus(
      formData?.ClientData?.data?.data?.account_status?.status
    );
    setCity(formData?.ClientData?.data?.data?.city);
    setState(formData?.ClientData?.data?.data?.state);
    setCountry(formData?.ClientData?.data?.data?.country);
    setLocation(formData?.ClientData?.data?.data?.location);
  };

  const onChangeEmailVerified = (e) => {
    e.target.value === 1 ? setEmailVerified(true) : setEmailVerified(false);
  };
  const onChangePhoneVerified = (e) => {
    e.target.value === 1 ? setPhoneVerified(true) : setPhoneVerified(false);
  };
  const onChangeGender = (e) => {
    e.target.value === 1 ? setGender("MALE") : setGender("FEMALE");
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      const respUpdate = await UpdateSingleClient(
        props.id,
        fName,
        lName,
        description,
        email,
        phone,
        gender,
        countryCode,
        city,
        state,
        country,
        location,
        emailVerified,
        clientLevel,
        phoneVerified,
        clientAccountStatus
      );
      console.log(respUpdate);
      message.success(`Updated  Client Succesfully  `, 2, () => {
        setConfirmLoading(false);
        props.CloseClientForm();
        props.refreshClientPage();
      });
    } catch (error) {
      message.warning(`Error Updating client`, 2, () =>
        setConfirmLoading(false)
      );
    } finally {
    }
  };

  const handleAddress = (getAddress) => {
    setLocation([
      getAddress.geometry.location.lng(),
      getAddress.geometry.location.lat(),
    ]);
    for (let add of getAddress.address_components) {
      const componentType = add.types[0];
      // eslint-disable-next-line default-case
      switch (componentType) {
        case "locality": {
          setCity(add.long_name);
          break;
        }
        case "administrative_area_level_1": {
          setState(add.long_name);
          break;
        }
        case "country": {
          setCountry(add.long_name);
          break;
        }
      }
    }
  };

  return (
    <Modal
      title="Edit Client Profile"
      visible={props.showEditClientForm}
      onOk={handleOk}
      okText="Update"
      confirmLoading={confirmLoading}
      onCancel={props.CloseClientForm}
    >
      <div className="editAgentProfile">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="general">
            <h3
              style={{
                padding: "0",
              }}
            >
              General Setting
            </h3>
          </div>
          <div className="formInputContainer">
            <label style={{}}>First Name</label>
          </div>
          <Input
            style={{
              padding: "10px",
              borderRadius: "5px",
            }}
            value={fName}
            onChange={(e) => setFName(e.target.value)}
            placeholder="Enter the First name"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Last Name</label>
          <Input
            value={lName}
            onChange={(e) => setLName(e.target.value)}
            placeholder="Enter the Last name"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Country Code</label>
          <Input
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            placeholder="Enter the Countrycode"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Phone</label>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter The Number"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Email</label>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter The Email"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Phone Verified</label>
          <Radio.Group
            onChange={onChangePhoneVerified}
            value={phoneVerified === true ? 1 : 2}
          >
            <Radio value={1}>True</Radio>
            <Radio value={2}>False</Radio>
          </Radio.Group>
        </div>
        <div className="formInputContainer">
          <label>Description</label>

          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter The Description"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Address</label>
          <Autocomplete
            className="ant-input"
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            defaultValue={defaultAddress}
            onPlaceSelected={(place) => {
              handleAddress(place);
            }}
          />
        </div>
        <div className="formInputContainer">
          <div className="addressBar">
            <Input
              value={address?.zip}
              onChange={(e) =>
                setAddress((prev) => {
                  return { ...prev, zip: e.target.value };
                })
              }
              placeholder="zip"
            ></Input>
            <label>ZIP</label>
          </div>
        </div>
        {/* <div className="formInputContainer">
          <label>Availability</label>
          <Radio.Group
            onChange={(e) =>
              e.target.value == 1
                ? setAvailability("AVAILABLE")
                : setAvailability("UNAVAILABLE")
            }
            value={availability == "AVAILABLE" ? 1 : 2}
          >
            <Radio value={1}>Available</Radio>
            <Radio value={2}>Unavailable</Radio>
          </Radio.Group>
        </div> */}
        <div className="formInputContainer">
          <label>Account Status</label>
          <Select
            defaultValue={clientAccountStatus}
            style={{ maxWidth: 150 }}
            onChange={(value) => setClientAccountStatus(value)}
          >
            <Option value="VERIFIED">VERIFIED</Option>
            <Option value="UNVERIFIED">UNVERIFIED</Option>
            <Option value="UNDER_PROCESS">UNDER_PROCESS</Option>
            <Option value="SUSPENDED">SUSPENDED</Option>
            <Option value="DEACTIVATED">DEACTIVATED</Option>
            <Option value="REJECTED">REJECTED</Option>
            <Option value="DELETED">DELETED</Option>
          </Select>
        </div>
        {/* <div className="formInputContainer">
          <label>Languages</label>
          <div
            // className="formInputContainer"
            style={{
              display: "flex",
            }}
          >
            <Input
              onChange={(e) => setLanguageValue(e.target.value)}
              placeholder="Add a Language"
            ></Input>
            <Button
              onClick={() => {
                setLanguage((prev) => [...new Set([...prev, languageValue])]);
              }}
            >
              <PlusSquareOutlined />
              Add Language
            </Button>
          </div>
          <div
            style={{
              border: "1px dashed #efefef",
              padding: "10px",
            }}
          >
            {language?.map((lang) => (
              <Tag
                closable
                color="blue"
                onClose={(e) => languagetagclose(lang)}
              >
                {lang}
              </Tag>
            ))}
          </div>
        </div> */}
        {/* <div className="formInputContainer">
          <label>Location</label>
          <Input placeholder="enter the First name"></Input>
        </div> */}
        {/* <div className="formInputContainer">
          <label>Skills</label>
          <div
            // className="formInputContainer"
            style={{
              display: "flex",
            }}
          >
            <Input
              onChange={(e) => setSkillValue(e.target.value)}
              placeholder="Add Agents Skills"
            ></Input>
            <Button
              type="default"
              onClick={() => {
                setSkills((prev) => [...new Set([...prev, skillValue])]);
              }}
            >
              <PlusSquareOutlined />
              Add Skill
            </Button>
          </div>
          <div
            style={{
              border: "1px dashed #efefef",
              padding: "10px",
            }}
          >
            {skills?.map((skill) => (
              <Tag
                closable
                color="success"
                onClose={(e) => skilltagclose(skill)}
              >
                {skill}
              </Tag>
            ))}
          </div>
        </div> */}
        <div className="formInputContainer">
          <label>Gender</label>
          <Radio.Group
            onChange={onChangeGender}
            value={gender == "MALE" ? 1 : 2}
          >
            <Radio value={1}>Male</Radio>
            <Radio value={2}>Female</Radio>
          </Radio.Group>
        </div>
        <div className="formInputContainer">
          <label>Level</label>
          <Input
            value={clientLevel}
            onChange={(e) => setClientLevel(e.target.value)}
            placeholder="Enter Agent's Level"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Email Verified</label>
          <Radio.Group
            onChange={onChangeEmailVerified}
            value={emailVerified === true ? 1 : 2}
          >
            <Radio value={1}>True</Radio>
            <Radio value={2}>False</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
}

export default EditClientProfile;
