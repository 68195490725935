import React, { useState } from "react";
import { Button, InputNumber, Modal, message } from "antd";

import "./subscriberList.css";
import { getPlanById, updatePlanById } from "../../../Services/apiClient";
import { Circles } from "react-loader-spinner";
import { blue } from "@ant-design/colors";

const InputModal = ({ open, handleOk, handleCancel, planId }) => {
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [discountPercent, setDiscountPercent] = useState();

  const getPlan = async () => {
    setLoading(true);
    try {
      if (!planId) return;
      const resp = await getPlanById({ id: planId });
      const { data } = resp?.data;
      const { status } = resp;
      console.log(data);
      if (status === 200) {
        setPlan(data);
        setAmount(data?.amount);
        setDiscountPercent(data?.discount);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updatePlan = async () => {
    const values = {
      id: planId,
      amount: amount,
      discount: discountPercent,
    };
    try {
      setLoading(true);
      const res = await updatePlanById({ values: values });
      const { status } = res;
      if (status === 200) {
        handleOk();
        message.success("Plan updated successfully");
      } else {
        handleOk();

        message.error(res?.data?.message);
      }
    } catch (err) {
      message.error(err?.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (planId !== undefined) {
      getPlan();
    }
  }, [planId]);

  return (
    <div>
      <Modal
        visible={open}
        title="Change Rate "
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={updatePlan}>
            Change
          </Button>,
        ]}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Circles
              height="30"
              width="30"
              color={blue[5]}
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div className="form_container">
            <div className="input_container">
              <label className="input_label">Original Rate : </label>
              <InputNumber
                defaultValue={plan?.amount?.toFixed(2)}
                min={0}
                max={10000000}
                formatter={(value) => `$ ${value}`}
                onChange={(value) => setAmount(value)}
              />
            </div>
            <div className="input_container">
              <label className="input_label">Discount Percent : </label>
              <InputNumber
                defaultValue={plan?.discount}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                onChange={(value) => setDiscountPercent(value)}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default InputModal;
