import React from "react";
import { Menu, Dropdown } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Logout({ handleLogOut }) {
  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/" style={{ color: "grayText" }}>
          <span
            onClick={() => {
              handleLogOut();
              // alert("hi");
            }}
          >
            Logout
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div onClick={() => handleLogOut()}>
      {/* <Dropdown overlay={menu}> */}
      <LogoutOutlined style={{ fontSize: "20px" }} />
      {/* </Dropdown> */}
    </div>
  );
}

export default Logout;
