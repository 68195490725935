import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  CarryOutOutlined,
  MoneyCollectOutlined,
  UserSwitchOutlined,
  Loading3QuartersOutlined
} from "@ant-design/icons";

function JobSteps({ jobSteps }) {
  const [stop, setStop] = useState(0);
  const { Step } = Steps;
  useEffect(() => {
    setStop((pre) => {
      switch (jobSteps?.toLowerCase()) {
        case "unpaid":
          return 1;
        case "client_payment_waiting":
          return 2;
        case "agent_payment_awaiting":
          return 3;
        case "processing":
          return 4;
        case "paid":
          return 5;
        case "refunded":
          return 6;

        default:
          break;
      }
    });
  }, []);
  return (
    <div
      className="jobSteps"
      style={{
        width: "100%",
      }}
    >
      <h4
        style={{
          marginBottom: "20px",
          
        }}
      >
       Payment Status
      </h4>
      <Steps
        direction="horizontal"
        size="small"
        responsive="true"
        current={stop}
        status="wait"
      >
        <Step title="Unpaid" icon={<UserOutlined />} />
        <Step title="Client Payment " icon={<SolutionOutlined />} />
        <Step title="Agent Payment " icon={<UserSwitchOutlined />} />
        <Step title="Processing" icon={stop == 4 ? <LoadingOutlined /> : <Loading3QuartersOutlined /> } />
        <Step title="Paid" icon={<MoneyCollectOutlined style={{
        color : `${stop == 5 ? '#0ae324' : ''}`
        }}/>} />
        <Step title="Refunded" icon={<CarryOutOutlined style={{
        color : `${stop == 6 ? '#faad14' : ''}`
        }}/>} />
      </Steps>
    </div>
  );
}

export default JobSteps;
