import React, { useEffect, useState } from "react";
import { Pagination, Table, Tag } from "antd";
import { AllJobProposals } from "../../../Services/apiClient";
import { Link } from "react-router-dom";

function JobProposals({ id }) {
  const [jobProposalData, setJobProposalData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(1);

  useEffect(() => {
    getProposalData();
  }, [offset]);

  const getProposalData = async () => {
    setLoader(true);
    try {
      const ProposalData = await AllJobProposals(id, offset);
      setJobProposalData(ProposalData?.data?.data?.proposals);
      setTotalRows(ProposalData?.data?.data?.count);
      setLoader(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: "Agent Name",
      dataIndex: ["agent", "first_name"],
      render: (indexData, record) => {
        // return <span>{`${indexData} ${record.agent.last_name}`}</span>
        return (
          <Link to={`/agentProfile/${record.agent_id}`}>
            {`${record.agent?.first_name} ${record.agent?.last_name}`}
          </Link>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render(status) {
        switch (status.toLowerCase()) {
          case "pending":
            return (
              <Tag color="default" key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          case "accepted":
            return (
              <Tag color="green" key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          case "rejected":
            return (
              <Tag color="red" key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          case "awarded":
            return (
              <Tag color="blue" key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          default:
            return (
              <Tag color="red" key={status}>
                {status.toUpperCase()}
              </Tag>
            );
        }
      },
    },
    {
      title: "Recieved Date",
      dataIndex: "created_at",
      render: (date) => {
        return <span>{new Date(date * 1000).toLocaleDateString()}</span>;
      },
      responsive: ["lg"],
    },
    {
      title: "Amount",
      dataIndex: "price",
      render: (amount) => {
        return <span>${amount}</span>;
      },
      responsive: ["md"],
    },
  ];

  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div>
      {
        <>
          <Table
            loading={loader}
            rowKey={(record) => record?._key}
            columns={columns}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{ margin: 0 }}>{record.description}</p>
              ),
              rowExpandable: (record) => record.description !== "",
            }}
            dataSource={jobProposalData ? jobProposalData : null}
          />
          <div className="pagination">
            <Pagination
              total={totalRows}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
              defaultCurrent={1}
              current={offset}
            />
          </div>
        </>
      }
    </div>
  );
}

export default JobProposals;
