import { Typography } from "antd";
import { SingleJobDispute } from "../../../Services/apiClient";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

const DisputeDetails = (props) => {
  const [disputedData, setDisputedData] = useState([]);
  useEffect(() => {
    getDisputeDetailedData();
  }, []);

  const { dispute } = useParams();
  const { Paragraph } = Typography;

  const getDisputeDetailedData = async () => {
    const DisputeDataDetails = await SingleJobDispute(dispute);
    setDisputedData(DisputeDataDetails?.data);
    DisputeDataDetails?.data?.data?.status == "RESOLVED" && props?.disableButton();
    DisputeDataDetails?.data?.data?.status == "CANCELED" && props?.disableButton();
  };
  console.log(disputedData);
  return (
    <>
      <h3>
        Created At -{" "}
        {
          new Date(disputedData?.data?.created_at * 1000)
            .toString()
            .split("G")[0]
        }
      </h3>
      <Paragraph>{disputedData?.data?.description}</Paragraph>
    </>
  );
};

export default DisputeDetails;
