import React, { useEffect, useState } from "react";
import { Pagination, Table, Tag, Spin, Alert } from "antd";
import { AllJobRequests } from "../../../Services/apiClient";
import { Link } from "react-router-dom";

function JobRequest({ id }) {
  const [jobRequestData, setJobRequestData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [error, setError] = useState(null);

  useEffect(() => {
    getRequestData();
  }, [offset]);

  const getRequestData = async () => {
    try {
      const requestData = await AllJobRequests(id, offset);
      setTotalRows(requestData?.data?.data?.count);
      setJobRequestData(requestData?.data?.data?.requests);
    } catch (error) {
      console.error("Error fetching job requests:", error);
      setError("Error fetching job requests. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: "Agent Name",
      dataIndex: ["agent", "first_name"],
      render: (indexData, record) => (
        <Link to={`/agentProfile/${record?.agent_id}`}>
          {`${record?.agent?.first_name} ${record?.agent?.last_name}`}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status.toLowerCase()) {
          case "pending":
            return <Tag color="default">{status?.toUpperCase()}</Tag>;
          case "accepted":
            return <Tag color="green">{status?.toUpperCase()}</Tag>;
          case "rejected":
            return <Tag color="red">{status?.toUpperCase()}</Tag>;
          default:
            return <Tag color="red">{status?.toUpperCase()}</Tag>;
        }
      },
    },
    {
      title: "Sent Date",
      dataIndex: "created_at",
      render: (date) => (
        <span>{new Date(date * 1000).toLocaleDateString()}</span>
      ),
      responsive: ["lg"],
    },
  ];

  const handlePageChange = (current) => {
    setOffset(current);
  };

  return (
    <div>
      {loader ? (
        <Spin tip="Loading..." />
      ) : error ? (
        <Alert message={error} type="error" showIcon />
      ) : (
        <>
          <Table
            columns={columns}
            pagination={false}
            rowKey={(record) => record?._key}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{ margin: 0 }}>{record.description}</p>
              ),
              rowExpandable: (record) => record.status !== "accepted",
            }}
            dataSource={jobRequestData ? jobRequestData : []}
          />
          <div className="pagination">
            <Pagination
              total={totalRows}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
              defaultCurrent={1}
              current={offset}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default JobRequest;
