import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AvatarProfile from "../../../Components/Profile/AvatarProfile";
import {
  AppleOutlined,
  AndroidOutlined,
  SnippetsOutlined,
  ScheduleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import JobsPosted from "../../JobFilters/JobsPosted";
import { Button, Skeleton, Tabs } from "antd";
import NewAgent from "../../../Components/Steps/NewAgent";
import ProfileInfo from "../../../Components/UserInfo/ProfileInfo/ProfileInfo";
import { SingleAgent } from "../../../Services/apiClient";
import EditAgentProfile from "./EditAgentProfile";
import { isImage, isPdf } from "./Pdfimg";
import ImageDemo from "./ImgPreview";
import "./AgentProfile.css";

function AgentProfile() {
  const { id } = useParams();
  const [agentProfile, setAgentProfile] = useState();
  const [agentJobsPosted, setAgentJobsPosted] = useState();
  const [loadings, setLoadings] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);
  const [re, setRe] = useState(false);
  const [isView, setISView] = useState(false);
  const ShowModal = () => {
    setShowEditForm(true);
  };
  const handleCancel = () => {
    setShowEditForm(false);
  };
  useEffect(() => {
    AgentProfileGetData();
  }, [re]);

  const refresh = () => {
    setRe((prev) => !prev);
  };
  const AgentProfileGetData = async () => {
    try {
      const resp = await SingleAgent(id);
      console.log(resp);
      setAgentProfile(resp?.AgentData?.data?.data);
      setAgentJobsPosted(resp?.JobsPosted?.data.data.jobs);
      setLoadings(false);
      console.log(resp);
    } catch (error) {
      console.log(error.response);
    }
  };

  console.log(agentProfile, "agentProfile");

  console.log(agentJobsPosted);

  function callback(key) {
    console.log(key);
  }
  const { TabPane } = Tabs;
  return (
    <div
      className="agentProfile"
      style={{
        margin: "0 auto",
      }}
    >
      <EditAgentProfile
        id={id}
        data={showEditForm}
        handleCancel={handleCancel}
        refresh={refresh}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Agent Profile</h2>
        <Button
          icon={
            <EditOutlined
              style={{
                color: "GrayText",
                fontSize: "13px",
              }}
            />
          }
          type="link"
          onClick={() => ShowModal()}
        >
          Edit Profile
        </Button>
      </div>
      {
        <Skeleton loading={loadings} active>
          <AvatarProfile
            name={`${agentProfile?.first_name} ${agentProfile?.last_name}`}
            location={`${agentProfile?.city ?? "-"} ${
              agentProfile?.state ?? "-"
            } ${agentProfile?.country ?? "-"}`}
            avatarSrc={agentProfile?.avatar}
            hourlyRate={agentProfile?.hourly_rate ?? 0}
            fixedRate={agentProfile?.fixed_rate ?? 0}
            shape="square"
          />
        </Skeleton>
      }

      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <SnippetsOutlined />
              About
            </span>
          }
          key="1"
        >
          <Skeleton loading={loadings} active>
            <ProfileInfo
              name={`${agentProfile?.first_name} ${agentProfile?.last_name} `}
              email={agentProfile?.email}
              phone={`${agentProfile?.country_code}-${agentProfile?.phone}`}
              total_ratings={agentProfile?.total_ratings}
              location={`${agentProfile?.city ?? "-"} ${
                agentProfile?.state ?? "-"
              } ${agentProfile?.country ?? "-"}`}
              registered={agentProfile?.created_at}
              id={agentProfile?.id}
              level={agentProfile?.level}
              status={agentProfile?.account_status?.status}
              phone_verified={agentProfile?.phone_verified}
              spent={agentProfile?.total_earned}
              hourlyRate={agentProfile?.hourly_rate}
              fixedRate={agentProfile?.fixed_rate}
              page={false}
              ssn={agentProfile?.ssn}
              tin={agentProfile?.tin}
            />
            {/* <div className="agentStatus" style={{ marginTop: "30px" }}>
              <NewAgent />
            </div> */}
            <div
              className="verifiedDocuments"
              style={{
                padding: "10px",
                backgroundColor: "white",
              }}
            >
              {agentProfile?.description ? (
                <>
                  <h2>Bio</h2>
                  <p>{agentProfile?.description}</p>
                </>
              ) : null}
              <h2>Documents</h2>
              <div
                className="imgContainer"
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  border: "1px dashed graytext",
                  padding: "20px",
                }}
              >
                {agentProfile?.documents?.length !== 0 ? (
                  <div>
                    {agentProfile?.documents?.map((doc) => {
                      return (
                        isImage(doc?.file) && <ImageDemo data={doc?.file} />
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <h1>NOT AVAILABLE</h1>
                  </div>
                )}
              </div>
              <div
                className="Skeleton"
                style={{
                  marginTop: "20px",
                }}
              >
                {isView ? (
                  [
                    agentProfile?.documents?.map((item) => {
                      return isPdf(item.file) ? (
                        <div className="pdfContainer">
                          <iframe
                            height="500px"
                            width="100%"
                            title={item.file}
                            src={`http://docs.google.com/gview?url=${process.env.REACT_APP_MINIO_URL}${item.file}&embedded=true`}
                            frameborder="0"
                          ></iframe>
                          {/* <object
                        width="100%"
                        height="400"
                        data={`http://157.245.99.216:9000/eabucket/${item.file}`}
                        type="application/pdf"
                      ></object> */}
                        </div>
                      ) : (
                        <h3
                          style={{
                            color: "#ff4d4f",
                          }}
                        >
                          Pdf not found...
                        </h3>
                      );
                    }),
                  ]
                ) : (
                  <h2>Click below to view the pdf if uploaded...</h2>
                )}
              </div>
              <div
                className="verificationView"
                style={{
                  padding: "10px 0px",
                }}
              >
                {agentProfile?.documents?.findIndex((item) =>
                  isPdf(item?.file)
                ) !== -1 && (
                  <Button onClick={() => setISView((prev) => !prev)}>
                    {isView ? "Close" : "View"}
                  </Button>
                )}
              </div>
            </div>
          </Skeleton>
        </TabPane>
        <TabPane
          tab={
            <span>
              <ScheduleOutlined />
              Jobs
            </span>
          }
          key="2"
        >
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="All jobs" key="1">
              {agentJobsPosted?.map((item) => (
                <JobsPosted data={item} />
              ))}
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AgentProfile;
