import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Input, message, Radio, Select, Tag } from "antd";
import { Option } from "antd/lib/mentions";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { SingleAgent, UpdateSingleAgent , GetSkills , UpdateSkills} from "../../../Services/apiClient";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

import "./EditAgentProfile.css";

function EditAgentProfile(props) {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [skills, setSkills] = useState([]);
  const [skillValue, setSkillValue] = useState("");
  const [language, setLanguage] = useState([]);
  const [languageValue, setLanguageValue] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [gender, setGender] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [address, setAddress] = useState({ street: '', city: '', country: '', zip: '' });
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [emailVerified, setEmailVerified] = useState(Boolean);
  const [agentLevel, setAgentLevel] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(Boolean);
  const [agentAccountStatus, setAgentAccountStatus] = useState("");
  const [availability, setAvailability] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [fixedRate, setFixedRate] = useState("");
  const [topSkillValue, setTopSkillValue] = useState("");
  const [topSkills, setTopSkills] = useState([]);
  const [location, setLocation] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState("");
  const [adminSkills, setAdminSkills] = useState([]);

  useEffect(() => {
    getEditableData();
    getSkillData();
  }, [props]);

  const getSkillData = async () => {
    const respGetSkillData = await GetSkills();
    setAdminSkills(respGetSkillData?.data?.data);
  };

  const getEditableData = async () => {
    const formData = await SingleAgent(props.id);
    console.log("form data", formData);
    setFName(formData?.AgentData?.data?.data?.first_name);
    setLName(formData?.AgentData?.data?.data?.last_name);
    setDescription(formData?.AgentData?.data?.data?.description);
    setEmail(formData?.AgentData?.data?.data?.email);
    setPhone(formData?.AgentData?.data?.data?.phone);
    setSkills(formData?.AgentData?.data?.data?.skills?.all_skills || []);
    setTopSkills(formData?.AgentData?.data?.data?.skills?.top_skills || []);
    setLanguage(formData?.AgentData?.data?.data?.languages);
    setGender(formData?.AgentData?.data?.data?.gender);
    setCountryCode(formData?.AgentData?.data?.data?.country_code);
    setPaypalEmail(formData?.AgentData?.data?.data?.paypal_email);
    setEmailVerified(formData?.AgentData?.data?.data?.email_verified);
    setAgentLevel(formData?.AgentData?.data?.data?.level);
    setPhoneVerified(formData?.AgentData?.data?.data?.phone_verified);
    setAgentAccountStatus(
      formData?.AgentData?.data?.data?.account_status?.status
    );
    setAvailability(formData?.AgentData?.data?.data?.availability);
    setHourlyRate(formData?.AgentData?.data?.data?.hourly_rate);
    setFixedRate(formData?.AgentData?.data?.data?.fixed_rate);
    setDefaultAddress(`${formData?.AgentData?.data?.data?.city} ${formData?.AgentData?.data?.data?.state} ${formData?.AgentData?.data?.data?.country}`)
    setLocation(formData?.AgentData?.data?.data?.location);
    setState(formData?.AgentData?.data?.data?.state);
    setCountry(formData?.AgentData?.data?.data?.country);
    setCity(formData?.AgentData?.data?.data?.city);
  };

  const onChangeEmailVerified = (e) => {
    e.target.value == 1 ? setEmailVerified(true) : setEmailVerified(false);
  };
  const onChangePhoneVerified = (e) => {
    e.target.value == 1 ? setPhoneVerified(true) : setPhoneVerified(false);
  };
  const onChangeGender = (e) => {
    e.target.value == 1 ? setGender("MALE") : setGender("FEMALE");
  };
  const handleOk = async () => {
    const respUpdate = await UpdateSingleAgent(
      props.id,
      fName,
      lName,
      description,
      email,
      phone,
      skills,
      topSkills,
      language,
      gender,
      countryCode,
      city,
      state,
      country,
      location,
      emailVerified,
      agentLevel,
      phoneVerified,
      agentAccountStatus,
      availability,
      paypalEmail,
      hourlyRate,
      fixedRate
    );
    if (respUpdate?.status == 200) {
      props.handleCancel();
      message.success(
        `Updated  SuccessFully ${respUpdate?.data?.data?.first_name}`,
        2.5,
        props.refresh()
      );
    } else {
      message.error(
        `couldn't update the profile with ${respUpdate?.data?.status}`
      );
    }

    const respUpdateSkillsList = await UpdateSkills(adminSkills);
    setAdminSkills(respUpdateSkillsList?.data?.data);
  };
  const skilltagclose = (e, tag) => {
    e.preventDefault();
    const updatedSkills = skills?.filter((item) => item !== tag);
    setSkills(updatedSkills);
  };

  const topSkilltagclose = (e, tag) => {
    e.preventDefault();
    const updatedTopSkills = topSkills?.filter((item) => item !== tag);
    setTopSkills(updatedTopSkills);
  };

  const languagetagclose = (e, lang) => {
    console.log("to remove", lang);
    e.preventDefault();
    const updatedLanguages = language?.filter((item) => {
      console.log("expresion", item);
      return item !== lang;
    });
    console.log("updates language", updatedLanguages);
    setLanguage(updatedLanguages);
  };

  const handleAddress = (getAddress) => {
    setLocation([getAddress.geometry.location.lng(),getAddress.geometry.location.lat()])
    for (let add of getAddress.address_components) {
      const componentType = add.types[0];
      switch (componentType) {
        case 'locality': {
          setCity(add.long_name)
          break;
        }
        case 'administrative_area_level_1': {
          setState(add.long_name)
          break;
        }
        case 'country': {
          setCountry(add.long_name)
          break;
        }
      }
    }

  };  

  return (
    <Modal
      title="Edit Agent Profile"
      visible={props.data}
      onOk={handleOk}
      okText="Update"
      // confirmLoading={confirmLoading}
      onCancel={props.handleCancel}
    >
      <div className="editAgentProfile">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="general">
            <h3
              style={{
                padding: "0",
              }}
            >
              General Setting
            </h3>
          </div>
          <div style={{}} className="formInputContainer">
            <label style={{}}>First Name</label>
          </div>
          <Input
            style={{
              padding: "10px",
              borderRadius: "5px",
            }}
            value={fName}
            onChange={(e) => setFName(e.target.value)}
            placeholder="enter the First name"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Last Name</label>
          <Input
            value={lName}
            onChange={(e) => setLName(e.target.value)}
            placeholder="Enter The First name"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Country Code</label>
          <Input
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            placeholder="Enter The Countrycode"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Phone</label>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter The Number"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Hourly Rate</label>
          <Input
            value={hourlyRate}
            onChange={(e) => setHourlyRate(e.target.value)}
            placeholder="Enter The Hourly Rate"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Fixed Rate</label>
          <Input
            value={fixedRate}
            onChange={(e) => setFixedRate(e.target.value)}
            placeholder="Enter The Fixed Rate"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Email</label>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter The Email"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Paypal_email</label>
          <Input
            value={paypalEmail}
            onChange={(e) => setPaypalEmail(e.target.value)}
            placeholder="Enter The Paypal Email"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Phone Verified</label>
          <Radio.Group
            onChange={onChangePhoneVerified}
            value={phoneVerified === true ? 1 : 2}
          >
            <Radio value={1}>True</Radio>
            <Radio value={2}>False</Radio>
          </Radio.Group>
        </div>
        <div className="formInputContainer">
          <label>Description</label>

          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter The Description"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Address</label>
          <Autocomplete
            className="ant-input"
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            defaultValue={defaultAddress}
            onPlaceSelected={(place) => {
              handleAddress(place)
            }} />
        </div>

        <div className="formInputContainer">
          <label>ZIP</label>
          <Input
            value={address?.zip}
            onChange={(e) =>
              setAddress((prev) => {
                return { ...prev, zip: e.target.value };
              })
            }
            placeholder="zip"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Availability</label>
          <Radio.Group
            onChange={(e) =>
              e.target.value == 1
                ? setAvailability("AVAILABLE")
                : setAvailability("UNAVAILABLE")
            }
            value={availability == "AVAILABLE" ? 1 : 2}
          >
            <Radio value={1}>Available</Radio>
            <Radio value={2}>Unavailable</Radio>
          </Radio.Group>
        </div>
        <div className="formInputContainer">
          <label>Account Status</label>
          <Select
            defaultValue={agentAccountStatus}
            style={{ maxWidth: 150 }}
            onChange={(value) => setAgentAccountStatus(value)}
          >
            <Option value="VERIFIED">VERIFIED</Option>
            <Option value="UNVERIFIED">UNVERIFIED</Option>
            <Option value="UNDER_PROCESS">UNDER_PROCESS</Option>
            <Option value="SUSPENDED">SUSPENDED</Option>
            <Option value="DEACTIVATED">DEACTIVATED</Option>
            <Option value="REJECTED">REJECTED</Option>
          </Select>
        </div>
        <div className="formInputContainer">
          <label>Languages</label>
          <div
            // className="formInputContainer"
            style={{
              display: "flex",
            }}
          >
            <Input
              onChange={(e) => setLanguageValue(e.target.value)}
              placeholder="Add a Language"
              value={languageValue}
            ></Input>
            <Button
              onClick={() => {
                setLanguage((prev) => [...new Set([...prev, languageValue])]);
                setLanguageValue("")
              }}
            >
              <PlusSquareOutlined />
              Add Language
            </Button>
          </div>
          <div
            style={{
              border: "1px dashed #efefef",
              padding: "10px",
            }}
          >
            {language?.map((lang) => (
              <Tag
                closable
                color="blue"
                style={{
                  marginBottom: "10px",
                }}
                onClose={(e) => languagetagclose(e, lang)}
              >
                {lang}
              </Tag>
            ))}
          </div>
        </div>
        <div className="formInputContainer">
          <label>Top Skills</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <Input
              value={topSkillValue}
              onChange={(e) => setTopSkillValue(e.target.value)}
              placeholder="Add Agent's top Skills"
            ></Input>
            <Button
              onClick={() => {
                setTopSkills((prev) => [...new Set([...prev, topSkillValue])]);
                setAdminSkills((prev) => [...new Set([...prev, topSkillValue])]);
                setTopSkillValue("");
              }}
            >
              <PlusSquareOutlined />
              Add Top Skill
            </Button>
          </div>
          <div
            style={{
              border: "1px dashed #efefef",
              padding: "10px",
            }}
          >
            {topSkills?.map((skill) => (
              <Tag
                closable
                color="success"
                onClose={(e) => topSkilltagclose(e, skill)}
              >
                {skill}
              </Tag>
            ))}
          </div>
        </div>
        <div className="formInputContainer">
          <label>Skills</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <Input
              value={skillValue}
              onChange={(e) => setSkillValue(e.target.value)}
              placeholder="Add Agent's Skills"
            ></Input>
            <Button
              onClick={() => {
                setSkills((prev) => [...new Set([...prev, skillValue])]);
                setAdminSkills((prev) => [...new Set([...prev, skillValue])]);
                setSkillValue("");
              }}
            >
              <PlusSquareOutlined />
              Add Skill
            </Button>
          </div>
          <div
            style={{
              border: "1px dashed #efefef",
              padding: "10px",
            }}
          >
            {skills?.map((skill) => (
              <Tag
                closable
                color="success"
                onClose={(e) => skilltagclose(e, skill)}
              >
                {skill}
              </Tag>
            ))}
          </div>
        </div>
        <div className="formInputContainer">
          <label>Gender</label>
          <Radio.Group
            onChange={onChangeGender}
            value={gender == "MALE" ? 1 : 2}
          >
            <Radio value={1}>Male</Radio>
            <Radio value={2}>Female</Radio>
          </Radio.Group>
        </div>
        <div className="formInputContainer">
          <label>Level</label>
          <Input
            onChange={(e) => setAgentLevel(e.target.value)}
            placeholder="Enter Agent's Level"
          ></Input>
        </div>
        <div className="formInputContainer">
          <label>Email Verified</label>
          <Radio.Group
            onChange={onChangeEmailVerified}
            value={emailVerified === true ? 1 : 2}
          >
            <Radio value={1}>True</Radio>
            <Radio value={2}>False</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
}

export default EditAgentProfile;
