import React, { useEffect, useState } from "react";
import { Card, Col, Pagination, Row, Statistic, Table,Tag } from "antd";
import { grey } from "@ant-design/colors";

import RevenueChart from "./RevenueChart";
import {
  getAllSubscribtions,
  getSubscriptionStats,
} from "../../../Services/apiClient";
import moment from "moment";

const TotalRevenue = () => {
  const [stats, setStats] = useState({
    activeSubscribers: 0,
    revenue: 0,
    taxAmount: 0,
    revenueWithoutTax: 0,
  });
  const [chartData, setChartData] = useState([]);
  const [tansactions, setTransactions] = useState([]);
  const [totalRows, setTotalRows] = useState(1);
  const [offset, setOffset] = useState(1);

  const getStats = async () => {
    try {
      const resp = await getSubscriptionStats();
      const { data } = resp;
      const { status } = resp;
      if (status === 200) {
        setStats({
          activeSubscribers: data?.active_subscribers,
          revenue: data?.revenue,
          taxAmount: data?.tax_amount,
          revenueWithoutTax: data?.revenue_without_tax,
        });
        const chartDataArr = data?.chart_data?.map((item) => {
          return {
            date: moment.unix(item?.timestamp).format("YYYY-MM"),
            amount: item?.amount,
          };
        });
        setChartData(chartDataArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubscriptions = async () => {
    try {
      const resp = await getAllSubscribtions({ clientId: "", offset: offset });
      const { data } = resp?.data;
      const { count } = resp?.data;
      const { status } = resp;
      console.log(data, "subscription revenue data");
      if (status === 200) {
        console.log(count, "count");
        setTotalRows(count);
        const transactionsArr = data?.map(
          ({ id, created, status, metadata, plan, default_tax_rates }) => {
            console.log(default_tax_rates, "dd");
            return {
              key: id ?? "--",
              date: moment.unix(created).format("MMM DD,YYYY") ?? "--",
              orderId: id ?? "--",
              clientName: `${metadata?.client_first_name ?? "-"} ${
                metadata?.client_last_name ?? "-"
              } `,
              status: status,
              grossAmount: `$${(plan?.amount / 100)?.toFixed(2)}` ?? 0,
              taxRate:
                default_tax_rates !== undefined &&
                default_tax_rates?.length !== 0
                  ? `${default_tax_rates[0]?.percentage}%`
                  : "--",
              taxAmount:
                default_tax_rates !== undefined &&
                default_tax_rates?.length !== 0
                  ? `$${(
                      plan.amount /
                      100 /
                      default_tax_rates[0]?.percentage
                    )?.toFixed(2)}`
                  : "--",
              totalAmount:
                default_tax_rates !== undefined &&
                default_tax_rates?.length !== 0
                  ? `$${(
                      plan?.amount / 100 +
                      plan.amount / 100 / default_tax_rates[0]?.percentage
                    )?.toFixed(2)}`
                  : `$${(plan?.amount / 100)?.toFixed(2)}`,
              status,
            };
          }
        );
        // console.log(subscribersArr, "sub data");

        setTransactions(transactionsArr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "green";
        if (
          status === "canceled" ||
          status === "incomplete" ||
          status === "expired"
        ) {
          color = "volcano";
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color = "green";
        if (status === "canceled" || status === "incomplete") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Gross Amount",
      dataIndex: "grossAmount",
      key: "grossAmount",
    },
    {
      title: "Tax Rate",
      dataIndex: "taxRate",
      key: "taxRate",
    },
    {
      title: "Tax Amount",
      dataIndex: "taxAmount",
      key: "taxAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  useEffect(() => {
    getStats();
    getSubscriptions();
  }, [offset]);

  return (
    <>
      <Row gutter={8}>
        <Col span={14}>
          <div>
            <RevenueChart chartData={chartData} />
          </div>
        </Col>
        <Col span={10}>
          <Row gutter={4}>
            <Col style={{ flex: 1 }}>
              <Card>
                <Statistic
                  title="Active Subscribers"
                  value={stats.activeSubscribers}
                  precision={0}
                  valueStyle={{ color: grey[7] }}
                />
              </Card>
            </Col>
            <Col style={{ flex: 1 }}>
              <Card>
                <Statistic
                  title="Revenue"
                  value={`$${stats.revenue.toFixed(2)}`}
                  precision={0}
                  valueStyle={{ color: grey[7] }}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={4} style={{ marginTop: "4px" }}>
            <Col style={{ flex: 1 }}>
              <Card>
                <Statistic
                  title="Tax Amount"
                  value={`$${stats.taxAmount.toFixed(2)}`}
                  precision={0}
                  valueStyle={{ color: grey[7] }}
                />
              </Card>
            </Col>
            <Col style={{ flex: 1 }}>
              <Card>
                <Statistic
                  title="Revenue Without Tax"
                  value={`$${stats.revenueWithoutTax.toFixed(2)}`}
                  precision={0}
                  valueStyle={{ color: grey[7] }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={tansactions}
            pagination={false}
          />
        </Col>
        <div className="pagination">
          <Pagination
            total={totalRows}
            onChange={handlePageChange}
            showSizeChanger={false}
            showQuickJumper={false}
            defaultCurrent={1}
            current={offset}
            // defaultPageSize={2}
          />
        </div>
      </Row>
    </>
  );
};

export default TotalRevenue;
