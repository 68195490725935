import React, { useState, useEffect } from "react";
import "./StatsLeft.css";
import { Spin } from "antd";
import millify from "millify";
import { AllStats } from "../../Services/apiClient";
// import DemoTinyArea, {
//   StatsCharts,
//   MiniHistogram,
//   MiniLines,
//   LittleArea,
// } from "./StatsCharts";
import {
  SyncOutlined,
  FileExclamationFilled,
  CloseSquareFilled,
  FundFilled,
  MehFilled,
  CheckSquareFilled,
  EyeFilled,
  WarningOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";

function StatsLeft() {
  const [statsData, setStatsData] = useState({});
  const [statsLoader, setStatsLoader] = useState(true);
  useEffect(() => {
    getStatsData();
  }, []);

  const getStatsData = async () => {
    const stats = await AllStats();
    setStatsData(stats?.data?.data);
    setStatsLoader(false);
  };

  console.log(statsData);

  return (
    <div
      className="statsWrapper"
      style={{
        display: "flex",
        width: "100%",

        // justifyContent: "space-between",
        gap: "25px",
        flexWrap: "wrap",
      }}
    >
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/jobs/ACTIVE"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Active Jobs</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.active_jobs || 0)}
              </span>
            )}
          </div>
          <div className="statsIcon statZero">
            <SyncOutlined
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
              spin
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/jobs/COMPLETED"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Completed Jobs</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.completed_jobs)}
              </span>
            )}
          </div>

          {/* <DemoTinyArea /> */}
          <div className="statsIcon statThird">
            <FundFilled
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/jobs/DISPUTED"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Disputed jobs</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.disputed_jobs || 0)}
              </span>
            )}
          </div>

          {/* <StatsCharts /> */}
          <div className="statsIcon statSecond">
            <WarningOutlined
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/jobs/OPEN"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Open Jobs</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.open_jobs || 0)}
              </span>
            )}
          </div>

          {/* <StatsCharts /> */}
          <div className="statsIcon openJobStat">
            <EyeFilled
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/jobs/CANCELED"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Canceled Jobs</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData?.canceled_jobs || 0)}
              </span>
            )}
          </div>
          {/* <StatsCharts /> */}
          <div className="statsIcon canceledJobStat">
            <CloseSquareFilled
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", color: "black", display: "contents" }}
        to="/agents/UNVERIFIED"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Unverified Agents</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.unverified_agents || 0)}
              </span>
            )}
          </div>

          <div className="statsIcon statOne">
            <FileExclamationFilled
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/agents/VERIFIED"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Verified Agents</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.verified_agents || 0)}
              </span>
            )}
          </div>

          {/* <StatsCharts /> */}
          <div className="statsIcon userStat">
            <MehFilled
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/clients/VERIFIED"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Verified Clients</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.verified_clients || 0)}
              </span>
            )}
          </div>
          {/* <StatsCharts /> */}
          <div className="statsIcon verifiedStat">
            <CheckSquareFilled
              style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      </Link>
      <Link
        style={{ width: "100%", display: "contents", color: "black" }}
        to="/agents/UNDER_PROCESS"
      >
        <div className="first" style={{}}>
          <div className="innerStats">
            <p>Under Process Agents</p>
            {statsLoader ? (
              <span>
                <Spin spinning={statsLoader}></Spin>
              </span>
            ) : (
              <span style={{ fontSize: "24px" }}>
                {millify(statsData.under_process_agents || 0)}
              </span>
            )}
          </div>
          {/* <StatsCharts /> */}
          <div className="statsIcon underProcessAgents">
            <LoadingOutlined style={{
                fontSize: "24px",
                margin: "0 auto",
              }}
              spin />
          </div>
        </div>
      </Link>
    </div>
  );
}

export default StatsLeft;
