import { useState } from "react";
import { Link } from "react-router-dom";
import { message } from "antd";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { AuthLogin } from "../../Services/apiClient";
import "./Login.css";

import LogoForm from "../../Components/images/Logo.svg";

const NormalLoginForm = ({ handleLogged }) => {
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);

  const CheckUser = async () => {
    setSpinner(true);
    const serverResponse = await AuthLogin(userName, password);
    console.log(serverResponse);
    if (serverResponse?.data?.status === 200) {
      console.log("logged in");
      message.success(serverResponse?.data?.message || "Welcome to EA");
      localStorage.setItem("token", serverResponse?.data?.data?.token);
      localStorage.setItem(
        "user",
        JSON.stringify(serverResponse?.data?.data?.user)
      );
      handleLogged();
    } else {
      console.log(serverResponse?.data?.status);
      switch (serverResponse?.data?.status) {
        case serverResponse?.data?.status >= 400:
          message.error(
            `Error Logging in ${
              serverResponse?.data?.error || "Client side Error"
            }`
          );
          break;
        case serverResponse?.data?.status >= 500:
          message.error(
            `Internal Server Error :- ${
              serverResponse?.data?.error || "Db down"
            }`
          );
          break;

        default:
          message.warning(
            `Error Logging in ${
              serverResponse?.data?.error || "Check Your Connection"
            }`
          );
          break;
      }
    }

    setSpinner(false);
  };
  return (
    <div className="loginForm">
      <div
        className="loginCard"
        style={{
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item>
            <img
              style={{
                width: "50%",
                marginBottom: "20px",
              }}
              src={LogoForm}
              alt="v"
            ></img>
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={() => CheckUser()}
              loading={spinner}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default NormalLoginForm;
