import React, { useEffect, useState } from "react";
import { Descriptions } from "antd";
import millify from "millify";
import { useHistory } from "react-router-dom";

function ProfileInfo({
  name,
  email,
  phone,
  total_ratings,
  location,
  registered,
  status,
  id,
  level,
  phone_verified,
  address,
  spent,
  isClient,
  hourlyRate,
  fixedRate,
  ssn,
  tin,
  rejectReasons,
}) {
  const [addressDetails, setAddress] = useState();
  const history = useHistory();
  useEffect(() => {
    // status == "UNVERIFIED" && history.push(`/agentVerification/${id}`);
  }, [status]);
  return (
    <div
      className="profileInfo"
      style={{
        padding: "10px",
        backgroundColor: "white",
        marginBottom: "20px",
      }}
    >
      <Descriptions title="User Info" bordered>
        <Descriptions.Item label="Id">{id?.slice(0, 6)}</Descriptions.Item>
        <Descriptions.Item label="Name" span={2}>
          {name}
        </Descriptions.Item>

        {/* <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item> */}
        <Descriptions.Item label="Registered">
          <span>{new Date(registered * 1000).toLocaleDateString()}</span>
        </Descriptions.Item>
        <Descriptions.Item label="email" span={2}>
          {email}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={2}>
          {status?.toLowerCase() === "verified" ? (
            <h3
              style={{
                color: "deepskyblue",
              }}
            >
              {status}
            </h3>
          ) : (
            <h3
              style={{
                color: "orange",
              }}
            >
              {status}
            </h3>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={`Amount ${isClient ? "Spent" : "Earned"}`}>
          ${millify(spent)}
        </Descriptions.Item>
        <Descriptions.Item label="Contact" span={2}>
          {" "}
          {phone}{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Ratings">
          {Math.round((total_ratings + Number.EPSILON) * 10) / 10}
        </Descriptions.Item>

        <Descriptions.Item label="Address" span={2}>
          {location ? (
            <p
            // style={{
            //   height: "100px",
            // }}
            >
              {location}
            </p>
          ) : (
            <span>Not Available</span>
          )}
        </Descriptions.Item>
        {!isClient && (
          <Descriptions.Item label="Hourly Rate">
            {`$${hourlyRate}/hour`}
          </Descriptions.Item>
        )}
        {/* <Descriptions.Item label="Level">{level}</Descriptions.Item> */}
        <Descriptions.Item label="Phone Verified" span={2}>
          {phone_verified ? (
            <h3
              style={{
                color: "yellowgreen",
              }}
            >
              Verified
            </h3>
          ) : (
            <h3
              style={{
                color: "tomato",
              }}
            >
              Unverified
            </h3>
          )}
        </Descriptions.Item>
        {!isClient && (
          <Descriptions.Item label="Fixed Rate">{`$${fixedRate}/job`}</Descriptions.Item>
        )}
        {!isClient && (
          <>
            <Descriptions.Item label="Social Security Number">
              {ssn || "--"}
            </Descriptions.Item>
            <Descriptions.Item label="TIN">{tin || "--"}</Descriptions.Item>
          </>
        )}
      </Descriptions>
    </div>
  );
}

export default ProfileInfo;
