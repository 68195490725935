import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/charts";
import { Spin } from "antd";
import { LeftChartData } from "../../Services/apiClient";
import { DatePicker } from "antd";
import "./LeftChart.css";
import moment from "moment";
const LeftChart = () => {
  const [data2, setData2] = useState([]);
  const [loader, setLoader] = useState(true);
  const [start, setStart] = useState(1627776000);
  const [end, setEnd] = useState(Math.floor(new Date().getTime() / 1000));
  const { RangePicker } = DatePicker;
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const respData = await LeftChartData(start, end);
    console.log("get data resp", respData);
    setData2((prev) => getMapData(respData?.data?.data));
  };

  const getMapData = (chart) => {
    console.log("getmapdata initial", chart);
    const d = chart?.map((item) => {
      const i = Object?.entries(item)[0];
      const date = new Date(Number(i[0]) * 1000).toLocaleDateString("default", {
        month: "long",
      });
      return {
        month: [date][0],
        value: i[1],
      };
    });
    setLoader(false);
    console.log("after loader", d);
    return d;
  };

  const onChange = (dates, dateStrings) => {
    console.log(Date.parse(dateStrings[0]));
    console.log(Date.parse(dateStrings[1]));
    setStart((prev) => Date.parse(dateStrings[0]) / 1000);
    setEnd((prev) => Date.parse(dateStrings[1]) / 1000);
    getData();
  };

  var config = 0
    ? {
        data: [
          {
            month: "april",
            value: "109",
            category: "Ea Revenue",
          },
        ],
        xField: "month",
        yField: "value",
        seriesField: "category",
        yAxis: {
          label: {
            formatter: function formatter(v) {
              return ""
                .concat(v)
                .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                  return "".concat(s, ",");
                });
            },
          },
        },

        color: ["#1979C9", "#FAA219", "#efefef", "#fe4r4r", "#D62A0D"],
      }
    : {
        data: data2,
        xField: "month",
        yField: "value",
        padding: "auto",
        autoFit: "true",
        yAxis: {
          label: {
            formatter: function formatter(v) {
              return ""
                .concat(v)
                .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                  return "".concat(s, ",");
                });
            },
          },
        },
        style: {
          outerHeight: "300px",
        },

        color: ["#D62A0D"],
      };
  console.log(data2);
  return loader ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        placeItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin spinning={loader} tip="Loading..."></Spin>
    </div>
  ) : (
    <div>
      <div
        className="chartHeader"
        style={{
          // alignSelf: "flex-end",
          marginBottom: "13px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          placeItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <RangePicker
          defaultValue={[moment("2021/08/01", "YYYY/MM/DD"), moment()]}
          className="picker"
          // size="small"
          onChange={onChange}
          bordered={false}
        />
        <h4
          style={{
            fontSize: "16px",
            fontWeight: "700",
            color: "rgb(98 97 97)",
            display: "contents",
          }}
        >
          Revenue Chart
        </h4>
      </div>

      <div className="chartContainer">
        <Line {...config} />
      </div>
    </div>
  );
};

export default LeftChart;
