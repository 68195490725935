import React from "react";
import { Rate } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { ApiOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
function Cards({ title, name, ratings, avatar }) {
  return (
    <div
      className="cards"
      style={{
        // border : '1px solid #efefef',
        padding: "6px",
      }}
    >
      <div
        className="cardsHeader"
        style={{
          borderBottom: "1px dashed #efefef",
          width: "100%",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "12px",
            fontWeight: "400",
            color: "GrayText",
          }}
        >
          {title}
        </span>
      </div>
      <div
        className="cardsDetails"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          padding: "10px 0px",
        }}
      >
        <div className="cardsAvatar">
          <Avatar
            src={`${process.env.REACT_APP_MINIO_URL}${avatar}`}
            icon={<UserOutlined />}
            // onError={() => <ApiOutlined />}
          />
        </div>
        <div
          className="cardsRight"
          style={{
            fontSize: "12px",
          }}
        >
          <span>{name}</span>
          <div
            className="cardsRatings"
            style={{
              display: "flex",
              // alignItems : 'center',

              fontSize: "10px",
              color: "gray",
            }}
          >
            <span>
              <Rate
                style={{
                  fontSize: "10px",
                  marginRight: "5px",
                }}
                disabled
                allowHalf
                value={ratings}
              />
            </span>
            <span>
              {ratings > 0
                ? `${Math.round((ratings + Number.EPSILON) * 10) / 10} (5)`
                : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
