import {
  // BellOutlined,
  MenuOutlined,

  // MessageFilled,
  // MessageOutlined,
  // SettingOutlined,
} from "@ant-design/icons";
// import { Badge } from "antd";
import React from "react";

import Logout from "./Logout.js/Logout";
import "./DashBoardHeader.css";
function DashboardHeader({ SideHandler, handleLogOut }) {
  // const [count, setCount] = useState(5);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
        backgroundColor: "white",
        position: "sticky",
        top: "0",
        zIndex: "30",
        borderBottom: "1px solid #f4f4f4",
      }}
    >
      <div
        className="leftHeader"
        style={{
          cursor: "pointer",
        }}
      >
        <MenuOutlined onClick={() => SideHandler()} />
      </div>
      <div
        className="right"
        style={{
          display: "flex",
          gap: "20px",
          cursor: "pointer",
        }}
      >
        {/* <Badge count={count} size="small" style={{ fontSize: "10px" }}>
          <BellOutlined style={{ fontSize: "10px !important" }} />
        </Badge>

        <MessageOutlined style={{ fontSize: "20px " }} /> */}

        <Logout handleLogOut={handleLogOut} />
      </div>
    </div>
  );
}

export default DashboardHeader;
