import React, { useEffect } from "react";
import { Select } from "antd";
import { useParams } from "react-router";

function SelectFilter({ getData, placeholder, jobFilter, data }) {
  const { Option } = Select;
  const { args } = useParams();

  useEffect(() => {
    args && getData(args);
  }, [args]);

  console.log(args, "filterjobargs");
  function onChange(value) {
    getData(value);
  }
  return (
    <div>
      <Select
        style={{ cursor: "pointer", minWidth: "150px" }}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        value={jobFilter}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        // value={jobFilter}
        dropdownStyle={{ minWidth: "150px" }}
      >
        {data?.map((el) => {
          return <Option value={el}>{el}</Option>;
        })}
      </Select>
    </div>
  );
}

export default SelectFilter;
