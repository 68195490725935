import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
// import millify from "millify";
import { Input, Space } from "antd";
import { Modal } from "react-responsive-modal";
// import { Link } from "react-router-dom";
// import JobsListDate from "../../../Components/DateForLists/JobsListDate";
import SelectFilter from "../../../Components/Button/SelectFilter";
import Truncate from "react-truncate";
// import Tags from "../../../Components/MeterComponents/Tags";
import { ContactUsList } from "../../../Services/apiClient";
import "./Contact.css";
import "react-responsive-modal/styles.css";
import moment from "moment";

// import ContactMessagesModal from "../../../Components/ContactMessagesModal";

// import EllipsisWithTooltip  from "react-ellipsis-with-tooltip";
function AgentList() {
  const [agents, setAgents] = useState([]);
  const [agentStatusValue, setAgentStatusValue] = useState("");
  const [agentSearchValue, setAgentSearchValue] = useState("");
  const [agentListLoader, setAgentListLoader] = useState(true);
  const [offset, setOffset] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(1);

  const [modalMessage, setModalMessages] = useState("");

  const { Search } = Input;
  const options = {
    effect: "solid",
    place: "left",
    multiline: true,
    className: "toolTipClass",
  };
  const onSearch = (value) => console.log(value);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    console.log("hi");
    setOpen(false);
    console.log(open);
  };
  let mounted = true;
  useEffect(() => {
    getAgentList();
    return () => {
      mounted = false;
    };
  }, [agentSearchValue, agentStatusValue, offset]);

  const getAgentList = async () => {
    setAgentListLoader(true);
    try {
      const agentListData = await ContactUsList(offset);
      console.log("dddd", JSON.stringify(agentListData, null, 2));
      mounted && setAgents(agentListData?.data?.data?.messages);
    } catch (error) {
      console.log("getAgentlist", error);
    } finally {
      mounted && setAgentListLoader(false);
    }
  };
  const getData = (value) => {
    setOffset(1);
    console.log("in agent list getdata");
    setAgentStatusValue(value);
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      render: (date, agent) => {
        return <span>{moment.unix(date).format("DD-MM-YYYY HH:MM A")}</span>;
      },
      responsive: ["md"],
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      render: (fullname) => {
        return <span>{fullname}</span>;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      responsive: ["md"],
    },
    {
      title: "Message",
      dataIndex: "message",
      render: (message, messageData) => {
        return (
          <div
            onClick={() => {
              setModalMessages(message);
              setOpen(true);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <Truncate
              line={1}
              width={120}
              ellipsis={
                <span
                  style={{
                    color: "blue",
                  }}
                >
                  ...
                </span>
              }
            >
              {message}
            </Truncate>
          </div>
        );
      },
    },
    {
      title: "Device",
      dataIndex: "device",
      render: (device) => {
        return <span>{device}</span>;
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  const handlePageChange = (current) => {
    console.log(current, "cur");
    setOffset(current);
  };

  return (
    <div className="agentList">
      <div
        className="agentContainer"
        style={{
          margin: "0 auto",
          position: "relative",
          border: "1px solid #efefef",
        }}
      >
        <div className="innerJoblists" style={{}}>
          <div
            className="jobSearch"
            style={{
              display: "flex",
              // justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Modal open={open} onClose={onCloseModal} center>
              <div>
                <h2
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Message
                </h2>
                <p
                  style={{
                    minWidth: "200px",
                    textAlign: "justify",
                    fontSize: "14px",
                  }}
                >
                  {modalMessage}
                </p>
              </div>
            </Modal>
            <div
              className="selectFilter"
              style={
                {
                  // flex: 0.3,
                }
              }
            >
              {/* <SelectFilter
               getData={getData} placeholder="Filter Agents" 
                jobFilter={agentStatusValue}/> */}
            </div>
            <div
              className="jobsearch__input"
              style={{
                flex: 2,
                width: "100%",
              }}
            >
              <Space direction="vertical">
                {/* <Search
                  placeholder="Search Messages here"
                  allowClear
                  disabled
                  enterButton="Search"
                  size="medium"
                  onSearch={onSearch}
                  onChange={(e) => {
                    setAgentSearchValue(e.target.value);
                  }}
                /> */}
              </Space>
            </div>
          </div>
          <Table
            columns={columns}
            loading={agentListLoader}
            dataSource={agents}
            onChange={onChange}
            pagination={false}
          />
          <div className="pagination">
            <Pagination
              total={totalRows}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
              defaultCurrent={1}
              current={offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentList;
