import { Image } from "antd";

function ImageDemo({ data }) {
  console.log("image prev", data);
  return (
    <Image.PreviewGroup>
      <Image width={100} src={`${process.env.REACT_APP_MINIO_URL}${data}`} />
    </Image.PreviewGroup>
  );
}

export default ImageDemo;
